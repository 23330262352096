import { ProviderButton } from "@features/provider-button";
import { Game } from "@lobby/core/entities/game";
import { debounce, useModal } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { InputSearch } from "@shared/ui/input-search";
import { Modal } from "@shared/ui/modal/modal";
import { useSearch } from "@tanstack/react-router";
import { useCallback, useState } from "react";

export function ProvidersModal() {
  const { isOpen, close } = useModal("providers");

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={close} />
      <ProvidersComponent />
    </Modal>
  );
}

export function ProvidersComponent() {
  const { $t } = useTranslate();
  const { pid } = useSearch({ strict: false });
  const { close } = useModal("providers");
  const [searchResult, setSearchResult] = useState<NonNullable<typeof sectionsListItems> | null>(
    null,
  );

  const { data } = Game.useProvidersList();

  const sectionsListItems = data?.result.data ?? [];

  const handleSearch = useCallback(
    debounce((value: string) => {
      if (!value) {
        setSearchResult(null);
      } else {
        setSearchResult(sectionsListItems.filter(({ name }) => name.toLowerCase().includes(value)));
      }
    }),
    [sectionsListItems],
  );

  const items = searchResult ?? sectionsListItems;

  return (
    <Modal.Panel className="top-0 size-full max-h-full overflow-y-auto lg:top-10 lg:max-h-[43rem] lg:w-[44.375rem]">
      <Modal.Title className="relative justify-between">
        <div>{$t({ defaultMessage: "Providers" })}</div>
        <Modal.CloseButton onClick={close} />
      </Modal.Title>
      <InputSearch onChange={handleSearch} />
      <div className="tw-scrollbar mt-5 overflow-y-auto py-1">
        <div className="grid grid-cols-[repeat(auto-fill,minmax(8rem,1fr))] gap-2 lg:grid-cols-3 lg:gap-2.5">
          {items.map(({ name, id, stringId }) => (
            <ProviderButton
              key={id}
              name={name}
              id={id}
              stringId={stringId}
              isActive={pid === id}
              onClick={close}
              className="h-10 lg:h-12.5"
            />
          ))}
        </div>
      </div>
    </Modal.Panel>
  );
}
