import { emitter } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Button, Modal } from "@shared/ui";
import { useEffect, useState } from "react";

export function SuccessModal() {
  const { $t } = useTranslate();
  const [isOpened, setIsOpened] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const unbind = emitter.on("SUCCESS_MODAL_OPEN", (message) => {
      setIsOpened(true);
      setMessage(message);
    });

    return unbind;
  }, []);

  return (
    <Modal open={isOpened}>
      <Modal.Overlay />

      <Modal.Panel
        className="w-max max-w-[90%] rounded-5 border-1 border-mantis bg-mantis/30 p-2.5 lg:min-w-96 lg:max-w-[25rem]"
        centered
      >
        <div className="rounded-2 bg-cod-gray">
          <div className="px-5 py-5 lg:px-10 lg:py-5">
            <div className="text-center font-bold text-mantis text-sm/none lg:text-18/none">
              {$t({ defaultMessage: "success" }).toUpperCase()}
            </div>
            <p className="my-6 whitespace-break-spaces text-center font-medium text-sm lg:text-18">
              {message}
            </p>
            <Button type="success" onClick={() => setIsOpened(false)} className="mx-auto mt-2.5">
              {$t({ defaultMessage: "ok" }).toUpperCase()}
            </Button>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
}
