import { useTranslate } from "@lobby/ocb-intl";
import Tabs from "@shared/ui/tabs";

import { DepositTab, WithdrawTab } from "./components";

export function TransactionHistory() {
  const { $t } = useTranslate();

  return (
    <div className="mobile-only:relative flex h-full min-h-0 flex-col">
      <Tabs
        items={[
          {
            label: $t({ defaultMessage: "Deposit" }),
            children: <DepositTab />,
          },
          {
            label: $t({ defaultMessage: "Withdraw" }),
            children: <WithdrawTab />,
          },
        ]}
      />
    </div>
  );
}
