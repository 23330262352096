import { createNanoEvents } from "nanoevents";
import type { TLocale } from "./locales";
import type { ICongratsModalProps, OpenWalletIntegrationModalEventPayload } from "./types";

interface Events {
  CONGRATS_MODAL_OPEN: (params: ICongratsModalProps) => void;
  ERROR_MODAL_OPEN: (error: Error) => void;
  PLAYER_BALANCE_VISIBILITY_CHANGED: (isVisible: boolean) => void;
  PLAYER_LOGIN: () => void;
  PLAYER_LOGOUT: () => void;
  SET_LOCALE: (locale: TLocale) => void;
  SUCCESS_MODAL_OPEN: (message: string) => void;
  SUPPORT_CHAT_OPEN: () => void;
  THEME_CHANGED: (theme: "dark" | "light") => void;
  WARNING_MODAL_OPEN: (message: string) => void;
  WALLET_INTEGRATION_WARNING_MODAL_OPEN: (payload: OpenWalletIntegrationModalEventPayload) => void;
}

export const emitter = createNanoEvents<Events>();
