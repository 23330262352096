import { CategoryIcon } from "@features/category/category-icon";
import { clsx } from "clsx";
import type { ReactNode } from "react";
import { useNavigateToCategory } from "../lib/use-navigate-to-category";

interface ICategoryButtonProps {
  children: ReactNode;
  isActive: boolean;
  disabled?: boolean;
  id: string | number;
  onClick?: VoidFunction;
}

export function CategoryButton({
  children,
  isActive,
  disabled,
  id,
  onClick,
}: ICategoryButtonProps) {
  const navigate = useNavigateToCategory();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(id);
    }
  };

  return (
    <button
      className={clsx("category-btn group", isActive && "category-btn_active")}
      type="button"
      disabled={disabled}
      onClick={handleClick}
    >
      <CategoryIcon className="category-btn__icon shrink-0 text-20 text-dove-gray" id={id} />
      <span className="category-btn__label truncate">{children}</span>
    </button>
  );
}
