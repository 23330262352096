import { LanguageSelector } from "@features/language-selector";
import { useTranslate } from "@lobby/ocb-intl";
import { Button, SVGIcon } from "@shared/ui";

export function ErrorComponent({ error }: { error: any }) {
  const { $t } = useTranslate();

  return (
    <div className="absolute inset-0 flex-center mobile-only:flex-col gap-5 px-5 lg:gap-10">
      <div className="flex-center text-[6.25rem] text-white lg:text-[12rem]">
        <SVGIcon name="gamepad" />
      </div>
      <div className="flex max-w-[30rem] flex-col items-center lg:items-start">
        {error?.code ? (
          <h1 className="font-bold text-6xl">{error.code}</h1>
        ) : (
          <h1 className="font-bold text-4xl">{$t({ defaultMessage: "Unknown error" })}</h1>
        )}
        <p className="my-5 mobile-only:text-center text-14 lg:text-18">
          {$t({
            defaultMessage:
              "Sorry! Games are temporarily unavailable! Technical work is underway! We will fix everything soon.",
          })}
        </p>
        <Button onClick={() => window.location.reload()}>
          {$t({ defaultMessage: "Reload page" })}
        </Button>
        <hr className="my-5 w-full text-dark-charcoal" />
        <div className="flex w-full items-center justify-between">
          <LanguageSelector orientation="top" />
        </div>
      </div>
    </div>
  );
}
