import { clsx } from "clsx";
import type { ReactNode } from "react";
import { SVGIcon } from "../svg-icon";

interface IButtonProps {
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  type?: "primary" | "danger" | "success" | "warning" | "default";
  htmlType?: "button" | "submit" | "reset";
  loading?: boolean;
  fit?: boolean;
  suppressed?: boolean;
  onClick?: VoidFunction;
}

export function Button({
  className,
  children,
  disabled,
  type,
  htmlType = "button",
  loading,
  fit,
  suppressed,
  onClick,
}: IButtonProps) {
  return (
    <button
      className={clsx(
        "btn",
        type ? `btn_${type}` : "btn_default",
        fit && "btn_fit",
        suppressed && "btn_suppressed",
        loading && "pointer-events-none cursor-default",
        className,
      )}
      type={htmlType}
      disabled={disabled}
      onClick={onClick}
    >
      <span className={clsx("inline-block", loading && "invisible")}>{children}</span>
      {loading && (
        <span className="absolute animate-spin">
          <SVGIcon name="loading" />
        </span>
      )}
    </button>
  );
}
