export const WHEEL_DIAMETER = 768;
export const WHEEL_RADIUS = WHEEL_DIAMETER / 2;

export const WHEEL_INNER_DIAMETER = WHEEL_DIAMETER * 0.84;
export const WHEEL_INNER_RADIUS = WHEEL_INNER_DIAMETER / 2;

export const WHEEL_REVOLUTIONS_TO_WIN = 3;
export const WHEEL_INITIAL_VELOCITY = 3;
export const WHEEL_SECTORS_REPEAT_COUNT = 3;

export const sectorFillings = ["#4ADBFF", "#EE0000", "#00FF09", "#FFEE2E", "#F58F00", "#FF23B0"];

export const sectorColors: [string, string][] = [
  ["#007ecb", "#005b95"],
  ["#495165", "#262c35"],
  ["#11b6ab", "#09726b"],
  ["#e88d23", "#cd6e00"],
  ["#7052dd", "#5038a6"],
  ["#ff385c", "#992237"],
];
