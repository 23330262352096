import { clsx } from "clsx";

interface INoDataPlaceholderProps {
  className?: string;
  topText?: string;
  bottomText?: string;
  icon: React.ReactNode;
}

export function NoDataPlaceholder({
  className,
  topText,
  bottomText,
  icon,
}: INoDataPlaceholderProps) {
  return (
    <div className={clsx(className, "h-64 max-h-full min-h-36 flex-center")}>
      <div className="text-center font-medium text-base text-dove-gray lg:text-22">
        {topText && <div>{topText}</div>}
        <div className="my-3 text-[5rem] lg:my-5">{icon}</div>
        {bottomText && <div>{bottomText}</div>}
      </div>
    </div>
  );
}
