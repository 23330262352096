import { Payment, usePlayerMoneyFormatter } from "@lobby/core/entities";
import { useInfiniteScroll, useMobile } from "@lobby/core/shared";
import { translateDynamicMessage, useIntlT, useTranslate } from "@lobby/ocb-intl";
import { SVGIcon } from "@shared/ui";
import { Spin } from "@shared/ui/spin";
import { getMobileSectionScrollContainer } from "@widget/profile-modal/lib";
import { useMemo } from "react";
import { NextPageLoader } from "../../components";
import { NoDataPlaceholder } from "../../no-data-placeholder";

type TransactionEntry = {
  id: string;
  amount: number;
  createdAt: string;
  type: "deposit" | "withdraw";
  status: "active" | "finished";
  currency: unknown;
};

export function DepositTab() {
  const { isLoading } = Payment.useTransactionHistory("deposit");

  if (isLoading) {
    return <Spinner />;
  }

  return <DepositTabContent />;
}

function DepositTabContent() {
  const { $t } = useTranslate();
  const isMobile = useMobile();

  const { data, fetchNextPage, isFetchingNextPage } = Payment.useTransactionHistory("deposit");

  const scrollContainer = isMobile ? getMobileSectionScrollContainer() : null;

  const scrollRef = useInfiniteScroll(fetchNextPage, scrollContainer);
  const deposits = useMemo(
    () => data?.pages.flatMap((page) => page.result?.data).filter(Boolean) ?? [],
    [data],
  );
  const isContentExist = deposits.length > 0 || isFetchingNextPage;

  return (
    <div className="tw-scrollbar gutter-stable relative flex min-h-0 flex-col mobile-only:pb-5 lg:h-full lg:max-h-full lg:overflow-y-auto">
      <div ref={scrollRef} className="tw-scrollbar gutter-stable overflow-y-auto">
        {isContentExist ? (
          <div className="lg:ml-2">
            <TransactionTable items={deposits} />

            {isFetchingNextPage && <NextPageLoader />}
          </div>
        ) : (
          <NoDataPlaceholder
            topText={$t({ defaultMessage: "You have no transactions" })}
            icon={<SVGIcon name="creditCard" className="text-[6.25rem]" />}
          />
        )}
      </div>
    </div>
  );
}

export function WithdrawTab() {
  const { isLoading } = Payment.useTransactionHistory("withdraw");

  if (isLoading) {
    return <Spinner />;
  }

  return <WithdrawTabContent />;
}

function WithdrawTabContent() {
  const { $t } = useTranslate();
  const isMobile = useMobile();

  const { data, fetchNextPage, isFetchingNextPage } = Payment.useTransactionHistory("withdraw");

  const scrollContainer = isMobile ? getMobileSectionScrollContainer() : null;

  const scrollRef = useInfiniteScroll(fetchNextPage, scrollContainer);

  const withdrawals = useMemo(
    () => data?.pages.flatMap((page) => page.result?.data).filter(Boolean) ?? [],
    [data],
  );
  const isContentExist = withdrawals.length > 0 || isFetchingNextPage;

  return (
    <div className="tw-scrollbar gutter-stable relative flex min-h-0 flex-col mobile-only:pb-5 lg:h-full lg:max-h-full lg:overflow-y-auto">
      <div ref={scrollRef} className="tw-scrollbar gutter-stable overflow-y-auto">
        {isContentExist ? (
          <div className="lg:ml-2">
            <TransactionTable items={withdrawals} />

            {isFetchingNextPage && <NextPageLoader />}
          </div>
        ) : (
          <NoDataPlaceholder
            topText={$t({ defaultMessage: "You have no transactions" })}
            icon={<SVGIcon name="creditCard" className="text-[6.25rem]" />}
          />
        )}
      </div>
    </div>
  );
}

function Spinner() {
  return (
    <div className="absolute inset-0 flex-center">
      <Spin />
    </div>
  );
}

function TransactionTable({ items }: { items: TransactionEntry[] }) {
  const { $t } = useTranslate();
  const { intl } = useIntlT();
  const formatMoney = usePlayerMoneyFormatter();

  return (
    <table className="bonuses-table lg:-ml-2">
      <thead>
        <tr>
          <th>{$t({ defaultMessage: "Date" })}</th>
          <th>{$t({ defaultMessage: "Payment type" })}</th>
          <th>{$t({ defaultMessage: "Sum" })}</th>
          <th>{$t({ defaultMessage: "Status" })}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.id}>
            <td>
              {new Date(item.createdAt).toLocaleString(intl.locale, {
                timeStyle: "short",
                dateStyle: "short",
              })}
            </td>
            <td>{item.type}</td>
            <td>
              {formatMoney(item.amount ?? 0, {
                style: "decimal",
              })}
            </td>
            <td className="first-letter:uppercase">
              {translateDynamicMessage($t, "transaction_status", item.status)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
