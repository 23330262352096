import { PlayerBalance } from "@features/player/player-balance";
import { Player, PlayerSettingsContext, usePlayerMoneyFormatter } from "@lobby/core/entities";
import { type TPlayerBalancesEx, emitter } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { SVGIcon, Skeleton } from "@shared/ui";
import { clsx } from "clsx";
import { useContext, useEffect, useRef, useState } from "react";
import type { PropsWithChildren } from "react";

export function ProfilePlayerBalanceView({ className }: { className?: string }) {
  const [isDummyFetching, setIsDummyFetching] = useState(false);
  const loadingIndicatorRef = useRef<HTMLDivElement>(null);

  const { $t } = useTranslate();

  const playerSettings = useContext(PlayerSettingsContext);

  const { data: player } = Player.usePlayer();
  const { data: balanceData, refetch, isFetching } = Player.useBalances();

  const isPending = isFetching || isDummyFetching;
  const balance = balanceData?.balance ?? ({} as TPlayerBalancesEx["balance"]);

  /*
    Regular balance - only balance field is present
    SS Dual balance - balance and win fields are present
    SS Single balance - all fields are present

    @link https://lobby-v2.stage.c23.games/api-docs/#/Player/Player.getBalancesEx
  */
  const isRegularBalance = Object.keys(balance).length === 1;

  function toggleBalanceVisibility() {
    emitter.emit("PLAYER_BALANCE_VISIBILITY_CHANGED", !playerSettings.isBalanceVisible);
  }

  const refetchBalances = async () => {
    if (isPending) return;

    setIsDummyFetching(true);
    await refetch();
    setTimeout(() => setIsDummyFetching(false), 1000);
  };

  useEffect(() => {
    if (isPending && loadingIndicatorRef.current) {
      loadingIndicatorRef.current.classList.add("animate-spin");
    }
  }, [isPending]);

  return (
    <div className={clsx(className, "rounded-xs bg-raisin-black p-3 lg:rounded-10 lg:p-5")}>
      <div className="flex-c-sb gap-5">
        <div className="truncate font-medium text-lg lg:text-base">
          {String(player?.displayableName)}
        </div>
        <div
          ref={loadingIndicatorRef}
          className="cursor-pointer"
          onClick={refetchBalances}
          onAnimationIteration={(e) =>
            !isPending && e.currentTarget.classList.remove("animate-spin")
          }
        >
          <SVGIcon className="text-lg" name="reload" />
        </div>
      </div>

      <div className="mt-5 flex-c-sb gap-3">
        <div className="flex items-center gap-1 font-medium uppercase">
          <span className="text-dove-gray">{$t({ defaultMessage: "Balance" })}</span>
          <button className="p-1" type="button" onClick={toggleBalanceVisibility}>
            {playerSettings.isBalanceVisible ? (
              <SVGIcon name="openedEye" />
            ) : (
              <SVGIcon name="closedEye" />
            )}
          </button>
        </div>
        <BalanceValue className="!h-7 !w-32" loading={playerSettings.isBalanceVisible && isPending}>
          <PlayerBalance className="font-medium lg:text-lg" />
        </BalanceValue>
      </div>

      {!isRegularBalance && <hr className="my-2 border-dark-charcoal" />}

      {!isRegularBalance && (
        <div className="space-y-2 text-12 lg:text-14">
          {"credit" in balance && (
            <BalanceRow
              title={$t({ defaultMessage: "Credit" })}
              value={balance.credit as number}
              loading={isPending}
            />
          )}
          {"bonus" in balance && (
            <BalanceRow
              title={$t({ defaultMessage: "Bonus" })}
              value={balance.bonus as number}
              loading={isPending}
            />
          )}
          {"win" in balance && (
            <BalanceRow
              title={$t({ defaultMessage: "Total win" })}
              value={balance.win as number}
              loading={isPending}
            />
          )}
          {"redeem" in balance && (
            <BalanceRow
              title={$t({ defaultMessage: "Redeemable" })}
              value={balance.redeem as number}
              loading={isPending}
            />
          )}
        </div>
      )}
    </div>
  );
}

interface IBalanceRowProps {
  title: string;
  value: number;
  loading: boolean;
}

function BalanceRow({ title, value, loading }: IBalanceRowProps) {
  const formatMoney = usePlayerMoneyFormatter();

  return (
    <div className="flex-c-sb gap-2">
      <span className="whitespace-nowrap text-dove-gray">{title}</span>
      <span className="-mb-2 min-w-10 grow border-dark-charcoal border-b-2 border-dotted" />
      <BalanceValue loading={loading}>{formatMoney(value)}</BalanceValue>
    </div>
  );
}

function BalanceValue({
  className,
  loading,
  children,
}: PropsWithChildren<{ className?: string; loading: boolean }>) {
  return loading ? (
    <Skeleton className={clsx("h-4 w-20 rounded-sm lg:h-5", className)} />
  ) : (
    <span className="truncate">{children}</span>
  );
}
