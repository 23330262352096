import { Logo, Modal } from "@shared/ui";
import { memo, useEffect, useRef, useState } from "react";
import {
  ProfileBalanceDetails,
  ProfilePlayerBalanceView,
  ProfileSectionControls,
  useProfileSections,
} from "../common";
import type { TProfileSectionItem } from "../common";

interface IProfileComponentDesktopProps {
  currentSection: TProfileSectionItem;
  showLoadingOverlay: boolean;
  close: VoidFunction;
}

export const ProfileComponentDesktop = memo(function ProfileComponentDesktop({
  currentSection = {} as TProfileSectionItem,
  showLoadingOverlay,
  close,
}: IProfileComponentDesktopProps) {
  const [panelHeight, setPanelHeight] = useState(0);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const sections = useProfileSections();

  useEffect(() => {
    if (sidebarRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setPanelHeight(entry.contentRect.height);
        }
      });

      observer.observe(sidebarRef.current);

      return () => observer.disconnect();
    }
  }, []);

  return (
    <Modal.Panel className="top-18 w-[62.5rem]">
      <Modal.Title className="items-start justify-between">
        <Logo className="h-10" />
        <Modal.CloseButton onClick={close} />
      </Modal.Title>

      <div className="grid h-full min-h-0 grid-cols-[minmax(18.5rem,22rem)_1fr] gap-10 *:min-h-0">
        <div ref={sidebarRef} className="flex h-fit flex-col gap-5">
          <ProfilePlayerBalanceView />
          <ProfileBalanceDetails />
          <ProfileSectionControls currentSectionId={currentSection.id} items={sections} />
        </div>
        <div
          className="relative flex grow flex-col overflow-hidden"
          style={{
            height: `${panelHeight}px`,
          }}
        >
          <div className="mb-5 font-medium text-22 text-dark-charcoal uppercase">
            {currentSection.title}
          </div>
          {currentSection.component}
        </div>
      </div>

      <Modal.PendingOverlay isActive={showLoadingOverlay} />
    </Modal.Panel>
  );
});
