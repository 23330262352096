import {
  BankAccountField,
  CardNumberField,
  CryptoWalletField,
  PhoneNumberField,
} from "./components";

export function penniesToDollars(amount = 0) {
  return Number.parseInt(String(amount / 100), 10);
}

export function selectPaymentAccountComponent(type = "") {
  switch (type) {
    case "crypto":
      return CryptoWalletField;
    case "bank_account":
      return BankAccountField;
    case "card":
      return CardNumberField;
    case "phone":
      return PhoneNumberField;
    default:
      return BankAccountField;
  }
}
