import { Lobby } from "@lobby/core/entities";
import { Skeleton } from "@shared/ui/skeleton";
import { useDesktopBanners } from "../lib/use-banner";
import { BannersListDesktop } from "./banners-list.desktop";

export function BannerPanelDesktop() {
  const { isPending } = Lobby.useBanner();
  const banners = useDesktopBanners();

  const isBannerEmpty = !banners || banners.length === 0;

  if (isBannerEmpty && !isPending) {
    return null;
  }

  return (
    <div className="group relative grid h-[15.75em] grid-cols-3 gap-5 text-[0.83333vw] widescreen:text-[1rem]">
      {isPending && (
        <>
          <Skeleton className="h-full" />
          <Skeleton className="h-full" />
          <Skeleton className="h-full" />
        </>
      )}
      {!isBannerEmpty && <BannersListDesktop banners={banners} />}
    </div>
  );
}
