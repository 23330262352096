import { Player } from "@lobby/core/entities";
import { useTranslate } from "@lobby/ocb-intl";
import { Button, Input, SVGIcon } from "@shared/ui";
import { useRef } from "react";
import type { FormEvent } from "react";

export function UserDataForm() {
  const { $t } = useTranslate();
  const displayableNameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);

  const updateMutation = Player.useUpdatePlayer();
  const { data: player } = Player.usePlayer();

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const displayableName = displayableNameRef.current?.value;
    const phone = phoneRef.current?.value;

    const isDisplayableNameUpdated = player?.displayableName !== displayableName;
    const isPhoneUpdated = player?.phone !== phone;

    if (isDisplayableNameUpdated || isPhoneUpdated) {
      updateMutation.mutate({
        displayableName,
        phone,
      });
    }
  }

  return (
    <form className="relative h-full" onSubmit={handleSubmit}>
      <Input
        label={$t({ defaultMessage: "Name" })}
        ref={displayableNameRef}
        type="text"
        name="username"
        placeholder={$t({ defaultMessage: "Username" })}
        pattern="[a-zA-Z0-9_\- ]{3,50}"
        title={$t({ defaultMessage: "3 to 50 letters, numbers, hyphens or underscores" })}
        defaultValue={String(player?.displayableName)}
        required
      />
      <Input
        className="mt-5"
        label={$t({ defaultMessage: "Phone" })}
        ref={phoneRef}
        type="tel"
        name="phone"
        placeholder={$t({ defaultMessage: "Phone" })}
        pattern="[0-9]{6,100}"
        title={$t({ defaultMessage: "6 to 100 numbers" })}
        defaultValue={player?.phone ?? ""}
      />
      {updateMutation.data?.error && (
        <div className="mt-5 text-carnation">{updateMutation.data.error.message}</div>
      )}
      <div className="right-0 bottom-0 mobile-only:mt-6 flex items-center mobile-only:justify-end gap-3 lg:absolute">
        {updateMutation.isPending && (
          <SVGIcon className={`${"animate-spin"} *:size-4`} name="reload" />
        )}
        <Button
          htmlType="submit"
          loading={updateMutation.isPending}
          className="mobile-only:mx-auto"
        >
          {$t({ defaultMessage: "Update" })}
        </Button>
      </div>
    </form>
  );
}
