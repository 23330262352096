import { apiClient } from "@lobby/api-client";
import { useMutation, useQuery } from "@tanstack/react-query";

import { emitter } from "../../../shared/lib";

export const MemberCard = {
  usePinGenerator() {
    return useMutation({
      mutationKey: ["MemberCard.generatePin"],
      mutationFn: () =>
        apiClient.send({
          endpoint: "MemberCard.generatePin",
        }),
    });
  },
  useCurrent() {
    return useQuery({
      queryKey: ["MemberCard.getCurrent"],
      queryFn: async () => {
        const result = await apiClient.send({
          endpoint: "MemberCard.getCurrent",
        });

        if (result.error?.code === 11000) {
          emitter.emit("ERROR_MODAL_OPEN", new Error("Can not get member card"));
        }

        return result;
      },
      select: (data) => data.result,
    });
  },
};
