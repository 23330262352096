import { Game } from "@lobby/core/entities";
import { range } from "@lobby/core/shared";
import { clsx } from "clsx";
import type { HTMLAttributes } from "react";
import { useGroupedGameList } from "../lib";
import { GameList } from "./index";

export function GameLists({ className, ...restProps }: HTMLAttributes<HTMLDivElement>) {
  const { isPending, isFetching } = Game.useList();

  const gameList = useGroupedGameList();

  return (
    <div className={clsx("flex flex-col gap-3 *:w-full lg:gap-5", className)} {...restProps}>
      {isPending || isFetching
        ? range(3)((idx) => <GameList.Skeleton key={idx} />)
        : gameList.map(({ type, label, id, games }) => {
            if (!label) {
              return null;
            }

            return (
              <GameList
                key={id.toString()}
                items={games}
                expandable={gameList.length > 1}
                expanded={gameList.length === 1}
                categoryId={id}
                type={type}
                label={label}
              />
            );
          })}
    </div>
  );
}
