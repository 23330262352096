import { Button, SVGIcon } from "@shared/ui";
import { clsx } from "clsx";
import { useEffect, useState } from "react";

interface IArrowProps {
  direction?: "left" | "right";
  disabled?: boolean;
  onClick: VoidFunction;
}

function Arrow({ direction, disabled, onClick }: IArrowProps) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      suppressed
      fit
      className={`${direction === "left" ? "" : "rotate-180"}`}
    >
      <SVGIcon className="text-10" name="arrowLeft" />
    </Button>
  );
}

interface IPaginationProps {
  className?: string;
  componentRef: HTMLDivElement | null;
}

export function Pagination({ className, componentRef }: IPaginationProps) {
  const [scrollPosition, setScrollPosition] = useState<"start" | "end" | "progress">("start");

  function onClickPrev() {
    componentRef?.scrollBy({
      left: -componentRef.children[0].clientWidth,
      behavior: "smooth",
    });
  }

  function onClickNext() {
    componentRef?.scrollBy({
      left: componentRef.children[0].clientWidth,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    function scrollListener(e: Event) {
      const { scrollWidth, scrollLeft, clientWidth } = e.currentTarget as HTMLDivElement;

      if (scrollLeft === 0) {
        setScrollPosition("start");
      } else if (Math.abs(scrollWidth - scrollLeft - clientWidth) < 1) {
        setScrollPosition("end");
      } else {
        setScrollPosition("progress");
      }
    }

    componentRef?.addEventListener("scroll", scrollListener);
    return () => componentRef?.removeEventListener("scroll", scrollListener);
  }, [componentRef]);

  return (
    <div className={clsx(className, "inline-flex gap-[0.3125rem]")}>
      <Arrow direction="left" onClick={onClickPrev} disabled={scrollPosition === "start"} />
      <Arrow onClick={onClickNext} disabled={scrollPosition === "end"} />
    </div>
  );
}
