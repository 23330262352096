import { Field, Input, Label, Radio } from "@headlessui/react";
import { usePlayerMoneyFormatter } from "@lobby/core/entities";
import { useTranslate } from "@lobby/ocb-intl";
import { Spin } from "@shared/ui/spin";
import type { ChangeEventHandler } from "react";

interface IPaymentAccountFieldProps {
  value: string | number;
  disabled: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export function BankAccountField({ value, disabled, onChange }: IPaymentAccountFieldProps) {
  const { $t } = useTranslate();

  return (
    <>
      <span>{$t({ defaultMessage: "Enter bank account number" })}</span>
      <Field disabled={disabled}>
        <Label className="block font-bold data-[disabled]:opacity-50">
          {$t({ defaultMessage: "Account number" })}:
        </Label>
        <Input
          className="w-80 appearance-none"
          placeholder={$t({ defaultMessage: "Bank account number" })}
          name="payment-account"
          type="text"
          value={value}
          required
          onChange={onChange}
        />
      </Field>
    </>
  );
}

export function CryptoWalletField({ value, disabled, onChange }: IPaymentAccountFieldProps) {
  const { $t } = useTranslate();

  return (
    <>
      <span>{$t({ defaultMessage: "Enter crypto wallet address" })}</span>
      <Field disabled={disabled}>
        <Label className="block font-bold data-[disabled]:opacity-50">
          {$t({ defaultMessage: "Crypto wallet address" })}:
        </Label>
        <Input
          className="w-80 appearance-none"
          placeholder={$t({ defaultMessage: "Crypto wallet address" })}
          name="payment-account"
          type="text"
          value={value}
          required
          onChange={onChange}
        />
      </Field>
    </>
  );
}

export function PhoneNumberField({ value, disabled, onChange }: IPaymentAccountFieldProps) {
  const { $t } = useTranslate();

  return (
    <>
      <span>{$t({ defaultMessage: "Enter phone number" })}</span>
      <Field disabled={disabled}>
        <Label className="block font-bold data-[disabled]:opacity-50">
          {$t({ defaultMessage: "Phone number" })}:
        </Label>
        <Input
          className="w-80 appearance-none"
          placeholder={$t({ defaultMessage: "Phone number" })}
          name="payment-account"
          autoComplete="tel"
          type="phone"
          value={value}
          required
          pattern="[0-9]{6,100}"
          onChange={onChange}
        />
      </Field>
    </>
  );
}

export function CardNumberField({ value, disabled, onChange }: IPaymentAccountFieldProps) {
  const { $t } = useTranslate();

  return (
    <>
      <span>{$t({ defaultMessage: "Enter card number" })}</span>
      <Field disabled={disabled}>
        <Label className="block font-bold data-[disabled]:opacity-50">
          {$t({ defaultMessage: "Card number" })}:
        </Label>
        <Input
          className="w-80 appearance-none"
          placeholder="xxxx xxxx xxxx xxxx"
          name="payment-account"
          autoComplete="cc-number"
          inputMode="numeric"
          type="tel"
          pattern="[0-9\s]{13,19}"
          required
          maxLength={19}
          value={value}
          onChange={onChange}
        />
      </Field>
    </>
  );
}

export function WalletSpinner() {
  return (
    <div className="absolute inset-0 flex-center">
      <Spin />
    </div>
  );
}

interface IPaymentMethodCardProps {
  id: number;
  name: string;
  logoUrl: string | null;
  commission?: string | null;
  limits?: {
    min: number;
    max: number;
  };
}

export function PaymentMethodCard({
  id,
  name,
  logoUrl,
  commission,
  limits,
}: IPaymentMethodCardProps) {
  const { $t } = useTranslate();
  const formatMoney = usePlayerMoneyFormatter();

  return (
    <Radio
      className="flex h-36 min-w-28 cursor-pointer appearance-none flex-col overflow-hidden rounded-5 border-2 border-raisin-black bg-raisin-black data-[checked]:border-mantis"
      as="div"
      value={id}
    >
      <div className="relative h-[6.875rem] px-[0.9375rem] pt-2.5 pb-5">
        <img className="mx-auto h-full object-contain" src={logoUrl ?? ""} alt={name} />
        {commission && Number(commission) > 0 && (
          <div className="absolute right-0 bottom-1 left-0 px-2.5 text-center font-medium text-10/none text-dove-gray uppercase">
            {$t({ defaultMessage: "commission: {commission}%" }, { commission })}
          </div>
        )}
      </div>

      {limits && (
        <div className="flex-1 flex-center whitespace-nowrap bg-cod-gray px-2.5 text-center text-14/none">
          {formatMoney(limits?.min ?? 0, { style: "decimal" })}
          {" - "}
          {formatMoney(limits?.max ?? 0)}
        </div>
      )}
    </Radio>
  );
}
