import { Game } from "@lobby/core/entities";
import { useAuth } from "@lobby/core/shared";
import clsx from "clsx";
import { FavouriteBtn, GameCardDescription, GameCardImage, GameCardLinkLayer } from "./components";

import "./styles.css";

interface IGameCardProps {
  imgSrc: string;
  title: string;
  provider: string;
  id: number;
  gameStringId: string;
  isFavourite: boolean;
  showWalletIntegrationWarning: boolean;
}

export function GameCard({
  imgSrc,
  title,
  provider,
  id,
  gameStringId,
  isFavourite,
  showWalletIntegrationWarning,
}: IGameCardProps) {
  const { isAuth } = useAuth();
  const favouriteMutation = Game.useFavouriteSetter();

  return (
    <div className="group game-card">
      <div className="h-full">
        <GameCardImage src={imgSrc} title={title} />
        <GameCardDescription provider={provider} gameName={title} />
      </div>

      <GameCardLinkLayer
        gameId={gameStringId}
        showWalletIntegrationWarning={showWalletIntegrationWarning}
      />
      <div className="game-card__frame" />

      {isAuth && (
        <FavouriteBtn
          className={clsx(
            "absolute top-2.5 right-2.5 mobile-only:hidden",
            isFavourite ? "visible" : "invisible group-hover:visible",
          )}
          isFavourite={isFavourite}
          onClick={() => favouriteMutation.mutate({ gameId: id, isFavourite: !isFavourite })}
        />
      )}
      {favouriteMutation.isPending && <div className="absolute inset-0 bg-white/60" />}
    </div>
  );
}
