import { CategoryButton } from "@features/category/category-button";
import { clientCategories } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { useSearch } from "@tanstack/react-router";

export function PrivateCategoryItems() {
  const { $t } = useTranslate();
  const { cid } = useSearch({ strict: false });

  return (
    <>
      <li>
        <CategoryButton
          isActive={String(cid) === clientCategories.favourites.id}
          id={clientCategories.favourites.id}
        >
          {$t({ defaultMessage: "Favourites" })}
        </CategoryButton>
      </li>
      <li>
        <CategoryButton
          isActive={String(cid) === clientCategories.recommended.id}
          id={clientCategories.recommended.id}
        >
          {$t({ defaultMessage: "Recommended" })}
        </CategoryButton>
      </li>
    </>
  );
}
