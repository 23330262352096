import bonusWinImg from "@app/assets/congrats-modal_box.avif";
import jackpotsWinImg from "@app/assets/congrats-modal_crown.avif";
import fortuneWheelWinImg from "@app/assets/congrats-modal_wheel.avif";
import type { CongratsModalType } from "@shared/lib";

export function getCongratsPrizeImage(type: CongratsModalType): string {
  switch (type) {
    case "bonus":
      return bonusWinImg;
    case "jackpot":
      return jackpotsWinImg;
    case "fortune-wheel":
      return fortuneWheelWinImg;
  }
}
