import { createContext, useEffect, useState } from "react";

import { emitter } from "../../shared/lib";

import type { PropsWithChildren } from "react";

const initialState = {
  isBalanceVisible: localStorage.getItem("isBalanceVisible") === "true",
};

export const PlayerSettingsContext = createContext(initialState);

export function PlayerSettingsContextProvider({ children }: PropsWithChildren) {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const subscribers = [
      emitter.on("PLAYER_BALANCE_VISIBILITY_CHANGED", (isVisible) => {
        setState((prev) => ({ ...prev, isBalanceVisible: isVisible }));
        localStorage.setItem("isBalanceVisible", String(isVisible));
      }),
    ];

    return () => {
      for (const unsubscribe of subscribers) {
        unsubscribe();
      }
    };
  }, []);

  return <PlayerSettingsContext.Provider value={state}>{children}</PlayerSettingsContext.Provider>;
}
