import type { ApiClientResponse } from "@lobby/api-client";
import { usePlayerMoneyFormatter } from "@lobby/core/entities";
import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { clsx } from "clsx";
import type { PropsWithChildren, ReactNode } from "react";
import { formatOdds } from "../lib/helpers";
import "./styles.css";

type TCoupon = ApiClientResponse<"History.getSport">["result"]["data"][number];

export function Coupon({
  createdAt,
  uid,
  eventsToGuessNum,
  description,
  typeEx,
  odds,
  bet,
  win,
  currency,
  status,
  bonusAmount = 0,
  outcomeStatus,
  events,
}: Omit<TCoupon, "type">) {
  const { $t } = useTranslate();
  const formatMoney = usePlayerMoneyFormatter();

  const isCancelled = status === "cancelled" || outcomeStatus === "cancelled";
  const isWin = outcomeStatus === "win";
  const isHalfWin = outcomeStatus === "half_win";
  const isHalfLost = outcomeStatus === "half_lost";
  const isDraw = outcomeStatus === "draw";
  const isLost = outcomeStatus === "lost";

  const hasBonus = bonusAmount > 0;

  const formattedDate = new Date(createdAt).toLocaleDateString();
  const formattedUid = uid?.match(/.{1,4}/g)?.join(" ") ?? uid;
  const formattedOdds = formatOdds(odds);
  const formattedBonus = formatMoney(bonusAmount, { currency });
  const formattedBet = formatMoney(bet, { currency });
  const formattedWin = formatMoney(win, { currency });

  let BottomRightStatus: ReactNode = <></>;
  if (hasBonus && (isWin || isHalfWin || isHalfLost || isDraw)) {
    BottomRightStatus = (
      <div className="br self-end">
        <span className="text-dove-gray">{$t({ defaultMessage: "Bonus" }) + skipIntl(": ")}</span>
        <span className="font-bold">{formattedBonus}</span>
      </div>
    );
  } else if (status === "cashout") {
    BottomRightStatus = (
      <div className="br self-end font-bold text-10 text-dove-gray uppercase lg:text-base">
        {$t({ defaultMessage: "cashout" })}
      </div>
    );
  } else if (isCancelled) {
    BottomRightStatus = (
      <div className="tr self-end font-bold text-10 text-dove-gray uppercase lg:text-base">
        {$t({ defaultMessage: "cancelled" })}
      </div>
    );
  }

  return (
    <div className="coupon">
      <div className={`px-3 py-1.5 lg:px-5 lg:py-2.5 ${isWin ? "bg-mantis" : "bg-dark-charcoal"}`}>
        <div className="flex-c-sb uppercase">
          <div className="whitespace-pre">
            <span className="font-bold">{formattedDate}</span>{" "}
            {typeEx === "single" ? typeEx : description}
          </div>
          {eventsToGuessNum > 1 && <div className="font-bold">{formattedOdds}</div>}
        </div>
      </div>
      {events.map((ev, idx) => {
        const isWinEvent = ev.status === "win";
        const isLoseEvent = ev.status === "lose";

        return (
          <div
            className={clsx(
              "flex flex-col gap-1.5 overflow-hidden bg-raisin-black px-3 py-1.5 lg:gap-2.5 lg:px-5 lg:py-2.5",
              idx > 0 && "rounded-t-12 border-dark-charcoal border-t-2 border-dashed",
              events.length > 1 && idx !== events.length - 1 && "rounded-b-12",
              `coupon-status_${isLoseEvent ? "lost" : ev.status}`,
            )}
            key={idx}
          >
            <div className="flex-c-sb font-bold">
              <div>{ev.name}</div>
              <div>{formatOdds(ev.odds)}</div>
            </div>
            <div className="flex-c-sb">
              <div className="text-accent">{ev.sport}</div>
              <div>{ev.categoryName}</div>
            </div>
            <div className="flex-c-sb">
              <div>
                <span className="font-bold">{ev.selName}</span>
                {skipIntl(" ")}
                <span className="font-light">{skipIntl(`(${ev.marketName})`)}</span>
              </div>
              <div className="size-4 lg:size-5.5">
                {isWinEvent ? (
                  <svg width="100%" height="100%" viewBox="0 0 20 21">
                    <path
                      className="fill-mantis"
                      d="M10 20.04A10 10 0 1 1 10.01.03a10 10 0 0 1-.01 20ZM16.47 6a1.33 1.33 0 0 0-1.88 0L9 11.58 6.03 8.65a1.33 1.33 0 0 0-1.88 1.87l3.8 3.76c.57.58 1.51.58 2.09 0l6.43-6.4c.52-.51.52-1.35 0-1.87Z"
                    />
                  </svg>
                ) : (
                  isLoseEvent && (
                    <svg width="100%" height="100%" viewBox="0 0 20 21">
                      <path
                        className="fill-carnation"
                        d="M10 20.04A10 10 0 1 1 10.01.03a10 10 0 0 1-.01 20Zm4.89-12.72c.53-.52.53-1.37 0-1.89a1.35 1.35 0 0 0-1.9 0L10 8.4 7 5.43a1.35 1.35 0 0 0-1.89 0 1.32 1.32 0 0 0 0 1.89l2.72 2.72-2.73 2.71a1.32 1.32 0 0 0 0 1.89c.53.52 1.38.52 1.9 0l3-2.97 3 2.97c.52.52 1.37.52 1.89 0 .53-.52.53-1.37 0-1.89l-2.73-2.71 2.73-2.72Z"
                      />
                    </svg>
                  )
                )}
              </div>
            </div>
          </div>
        );
      })}
      <div
        className={clsx(
          "status-win bg-raisin-black px-3 lg:px-5",
          `coupon-status_${outcomeStatus}`,
        )}
      >
        <hr className="border-1 text-dark-charcoal" />
        <div className="coupon-footer">
          {/* TOP LEFT */}
          <div className="tl whitespace-pre font-medium">
            <span className="text-dove-gray">{$t({ defaultMessage: "Bet" }) + skipIntl(": ")}</span>
            <span>{formattedBet}</span>
          </div>

          {/* TOP RIGHT */}
          {status === "cashout" ? (
            <OutcomeStatus>
              <span className="font-bold text-white">{formattedWin}</span>
            </OutcomeStatus>
          ) : (
            <>
              {isWin && (
                <OutcomeStatus>
                  <span>{$t({ defaultMessage: "Win" }) + skipIntl(": ")}</span>
                  <span className="font-bold text-white">{formattedWin}</span>
                  <WinArrow />
                </OutcomeStatus>
              )}
              {isHalfWin && (
                <OutcomeStatus>
                  <span>{$t({ defaultMessage: "Half win" }) + skipIntl(": ")}</span>
                  <span className="font-bold text-white">{formattedWin}</span>
                  <WinArrow />
                </OutcomeStatus>
              )}
              {isHalfLost && (
                <OutcomeStatus>
                  <span>{$t({ defaultMessage: "Half lose" }) + skipIntl(": ")}</span>
                  <span className="font-bold text-white">{formattedWin}</span>
                  <WinArrow />
                </OutcomeStatus>
              )}
              {isDraw && (
                <OutcomeStatus>
                  <span>{skipIntl("Draw: ")}</span>
                  <span className="font-bold text-white">{formattedWin}</span>
                </OutcomeStatus>
              )}
              {isLost && <OutcomeStatus>{$t({ defaultMessage: "Lose" })}</OutcomeStatus>}
            </>
          )}

          {/* BOTTOM LEFT */}
          <div className="bl self-end text-8 text-dove-gray lg:text-11">
            {$t({ defaultMessage: "ID" }) + skipIntl(": ")} {formattedUid}
          </div>

          {/* BOTTOM RIGHT */}
          {BottomRightStatus}
        </div>
      </div>
    </div>
  );
}

function OutcomeStatus({ children }: PropsWithChildren) {
  return (
    <div className="tr flex items-center justify-end whitespace-pre text-dove-gray">{children}</div>
  );
}

function WinArrow() {
  return (
    <svg className="ml-1 size-3 lg:size-3.5" viewBox="0 0 14 15">
      <path
        className="fill-mantis"
        d="M14 8.275 7.368.905.734 8.275h3.683v6.628h5.896V8.275h3.688Z"
      />
    </svg>
  );
}
