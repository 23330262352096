import { createFileRoute } from "@tanstack/react-router";

import { redirectIfFortuneWheelIsNotAvailable } from "@app/lib";
import { loadFortuneWheelSettings, loadGameCategoryList } from "@app/lib/loaders";
import { FortuneWheelPage } from "@pages/fortune-wheel";

export const Route = createFileRoute("/_auth/fortune-wheel")({
  beforeLoad: async () => redirectIfFortuneWheelIsNotAvailable(),
  loader: () => Promise.all([loadFortuneWheelSettings(), loadGameCategoryList()]),
  component: FortuneWheelPage,
  onError: console.error,
});
