export function formatTime(time: number) {
  const formattedTime = time / 1000;

  const hours = Math.floor(formattedTime / 3600);
  const minutes = Math.floor((formattedTime % 3600) / 60);
  const seconds = Math.floor(formattedTime % 60);

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
}
