import type { ApiClientResponse } from "@lobby/api-client";
import { queryClient } from "@lobby/core/app";
import { Chat, Player } from "@lobby/core/entities";
import { last } from "@lobby/core/shared";
import { useLocale, useTranslate } from "@lobby/ocb-intl";
import { SVGIcon } from "@shared/ui";
import { Spin } from "@shared/ui/spin";
import { clsx } from "clsx";
import { memo, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import type { HTMLAttributes } from "react";

interface IConversationWindowProps {
  conversationId: number | null;
  pendingMessage: string | null;
  isChatWindowExpanded: boolean;
  onError: (error: { message: string; code: number }) => void;
  onMessagesLoading: (isMessagesLoaded: boolean) => void;
}

type TChatMessage = {
  seqNum: number;
  type: "text";
  text: string;
  createdAt: number;
  readAt: unknown;
  from: {
    userId: number;
    userName: string;
  };
};

export const ConversationWindow = memo(function ConversationWindow({
  conversationId,
  pendingMessage,
  isChatWindowExpanded,
  onError,
  onMessagesLoading,
}: IConversationWindowProps) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const isScrollAtBottom = useRef(false);
  const lastScrollAreaHeight = useRef(0);

  const player = Player.usePlayer();
  const conversations = Chat.useConversationList();
  const {
    data: messagesData,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isMessagesLoading,
  } = Chat.useConversationHistory(conversationId);
  const locale = useLocale();

  const messages = useMemo(
    () => messagesData?.pages.flatMap((page) => page.result).reverse() ?? [],
    [messagesData],
  );

  const messagesExist = messages.length || pendingMessage;

  const conversation = conversations.data?.result?.[0] ?? null;
  const hasUnreadMessages = !(conversation?.isRead ?? true);

  function scrollToBottom() {
    scrollContainerRef.current?.scrollTo(0, scrollContainerRef.current.scrollHeight);
  }

  useLayoutEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    if (!isFetchingNextPage) {
      scrollContainer.scrollTo(0, scrollContainer.scrollHeight - lastScrollAreaHeight.current);
    }

    lastScrollAreaHeight.current = scrollContainer.scrollHeight ?? 0;
  }, [isFetchingNextPage]);

  useLayoutEffect(() => {
    onMessagesLoading(!isMessagesLoading);
    scrollToBottom();
  }, [isMessagesLoading]);

  useLayoutEffect(() => {
    if (isScrollAtBottom.current || pendingMessage) {
      scrollToBottom();
    }
  }, [messages, pendingMessage, isChatWindowExpanded]);

  useEffect(() => {
    const error = last(messagesData?.pages)?.error;
    if (error) {
      onError(error);
    }
  }, [messagesData]);

  useEffect(() => {
    if (hasUnreadMessages) {
      /* After fetching new messages (Conversation.getMessages), server marks them as read,
         and we need to make the same changes in the local cache to keep the data consistent.
         @see https://git.casinomodule.org/casino23/tickets/-/issues/3035#note_211671
      */
      queryClient.setQueryData<ApiClientResponse<"Conversation.getList">>(
        ["Conversation.getList"],
        (prevData) => {
          if (!prevData || !prevData.result) return prevData;

          prevData.result[0].isRead = true;

          return {
            ...prevData,
            result: {
              ...prevData.result,
            },
          };
        },
      );

      if (queryClient.isFetching({ queryKey: ["Conversation.getMessages"] }) === 0) {
        queryClient.invalidateQueries({ queryKey: ["Conversation.getMessages"] });
      }
    }
  }, [messagesData, hasUnreadMessages]);

  useEffect(() => {
    const handleScrollEvent = () => {
      const scrollContainer = scrollContainerRef.current;
      if (!scrollContainer) return;

      isScrollAtBottom.current =
        scrollContainer.scrollTop + scrollContainer.clientHeight === scrollContainer.scrollHeight;

      if (scrollContainer.scrollTop === 0) {
        fetchNextPage();
      }
    };

    scrollContainerRef.current?.addEventListener("scroll", handleScrollEvent);
    return () => scrollContainerRef.current?.removeEventListener("scroll", handleScrollEvent);
  }, [scrollContainerRef.current, messagesExist]);

  return (
    <div className="relative min-h-0 grow rounded-t-rounded bg-cod-gray">
      {messagesExist ? (
        <div
          className="tw-scrollbar relative h-full overflow-y-auto overscroll-contain p-2.5"
          ref={scrollContainerRef}
        >
          {isFetchingNextPage && (
            <div className="pointer-events-none absolute top-5 right-0 left-0 flex items-center justify-center">
              <SVGIcon className="animate-spin text-20 text-accent" name="reload" />
            </div>
          )}
          <ul id="chat-history-list" className="space-y-2.5">
            {messages.map((message) => (
              <ChatMessage
                key={message.seqNum}
                message={message}
                locale={locale}
                isFromCurrentUser={message.from.userId === player.data?.id}
              />
            ))}

            {pendingMessage && (
              <li data-owner="user" style={{ opacity: 0.5 }}>
                <div className="ml-auto flex w-fit max-w-[60%] flex-col gap-1 break-all rounded-10 rounded-br-3 bg-dark-charcoal px-2.5 py-2">
                  <span className="font-medium text-14 text-mantis">{player.data?.name}</span>
                  <p className="text-white leading-[1.125]">{pendingMessage}</p>
                  <span className="self-end font-light text-12 text-dove-gray">
                    {new Date(Date.now()).toLocaleTimeString(locale, {
                      timeStyle: "short",
                    })}
                  </span>
                </div>
              </li>
            )}
          </ul>
        </div>
      ) : isMessagesLoading ? (
        <div className="absolute inset-0 flex-center">
          <Spin />
        </div>
      ) : (
        <EmptyChatPlaceholder />
      )}
    </div>
  );
});

interface IChatMessageProps {
  message: TChatMessage;
  locale: string;
  isFromCurrentUser: boolean;
}

function formatDate(timestamp: number, locale: string) {
  const dt = new Date(timestamp);
  const diffDays = new Date().getDate() - dt.getDate();
  const diffMonths = new Date().getMonth() - dt.getMonth();
  const diffYears = new Date().getFullYear() - dt.getFullYear();

  const timeString = new Date(timestamp).toLocaleTimeString(locale, { timeStyle: "short" });
  const dateString = new Date(timestamp).toLocaleDateString(locale, { dateStyle: "short" });

  if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
    return timeString;
  } else {
    return `${timeString}, ${dateString}`;
  }
}

function ChatMessage({ message, locale, isFromCurrentUser }: IChatMessageProps) {
  return (
    <li data-owner={isFromCurrentUser ? "user" : "support"}>
      <div
        className={`flex w-fit max-w-[60%] flex-col gap-1 break-all rounded-10 px-2.5 py-2 ${isFromCurrentUser ? "ml-auto rounded-br-3 bg-dark-charcoal" : "rounded-bl-3 bg-raisin-black"}`}
      >
        <span
          className={`font-medium text-14 ${isFromCurrentUser ? "text-mantis" : "text-[#C69316]"}`}
        >
          {message.from.userName}
        </span>
        <p className="text-white leading-[1.125]">{message.text}</p>
        <span className="self-end font-light text-12 text-dove-gray">
          {formatDate(message.createdAt * 1e3, locale)}
        </span>
      </div>
    </li>
  );
}

export function MinimizeWindowBtn({ onClick }: { onClick: VoidFunction }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="relative size-8 flex-center rounded-4 hover:bg-jet"
    >
      <div className="absolute bottom-1 h-[0.1875rem] w-4 rounded-full bg-white" />
    </button>
  );
}

export function ExpandWindowBtn({
  className,
  onClick,
}: { className?: string; onClick: VoidFunction }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx("relative size-8 flex-center rounded-4 hover:bg-jet", className)}
    >
      <svg className="size-5" viewBox="0 0 20 20">
        <path
          fill="#FFF"
          d="M17.835 20H2.165A2.174 2.174 0 0 1 0 17.835V2.165C0 .976.976 0 2.165 0h6.023a1.366 1.366 0 0 1 0 2.73H2.73v14.54h14.542v-5.388a1.366 1.366 0 0 1 2.729 0v5.941c0 1.2-.977 2.177-2.165 2.177Zm.8-11.882a1.366 1.366 0 0 1-1.364-1.365V4.706l-9.26 9.259c-.529.53-1.4.53-1.929 0a1.37 1.37 0 0 1 0-1.93l9.306-9.306h-2.13a1.366 1.366 0 0 1-1.364-1.364A1.35 1.35 0 0 1 13.26 0h4.576C19.023 0 20 .976 20 2.165V6.74c0 .765-.612 1.377-1.365 1.377Z"
        />
      </svg>
    </button>
  );
}

export function SendMessageBtn({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: HTMLAttributes<HTMLButtonElement>["onClick"];
}) {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      onMouseDown={(e) => e.preventDefault()}
      className={`-translate-y-1/2 absolute top-1/2 right-5 flex-center text-white ${
        disabled ? "pointer-events-none" : "pointer-events-auto"
      }`}
    >
      <svg className="h-5 w-6" viewBox="0 0 24 20">
        <path
          fill="currentColor"
          d="M.68 10.836c.885.31 2.041.729 3.577 1.166 1.545.447 2.274.525 3.84-.496 1.564-1.02 6.968-4.714 7.649-5.19.68-.466 2.002-1.244 2.255-1.06.252.185-.321 1.06-1.186 1.896-.865.836-4.656 4.325-5.599 5.268-.943.943-.933 1.633.04 2.42.961.788 1.311.933 5.131 3.548 3.82 2.615 4.724 1.74 5.093-.428.37-2.167 2.07-13.18 2.382-15.318.165-1.098.291-1.934-.292-2.226-.573-.291-1.254-.136-2.624.438-2.78 1.176-17.7 7.25-19.556 8.038-1.857.777-1.594 1.633-.71 1.944Z"
        />
      </svg>
    </button>
  );
}

export function EmptyChatPlaceholder() {
  const { $t } = useTranslate();

  return (
    <div className="size-full flex-center flex-col gap-4">
      <p className="font-medium text-22 text-dove-gray tracking-tighter">
        {$t({ defaultMessage: "Have a question? Ask us!" })}
      </p>
    </div>
  );
}
