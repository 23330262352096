import { useTranslate } from "@lobby/ocb-intl";

import { Skeleton } from "@shared/ui";

interface IRulesSectionProps {
  rules?: { general: string; daily: string };
}

function RuleContent({ title, description }: { title: string; description: string | undefined }) {
  return (
    <div className="isolate w-96 rounded-5 bg-white/10 p-2.5">
      <div className="rounded-inherit bg-raisin-black px-8 py-6">
        <div className="mb-2.5 font-bold text-22 uppercase">{title}</div>
        {description ? (
          <p className="whitespace-pre-line text-18">{description}</p>
        ) : (
          <div className="flex w-full flex-col gap-1.5 *:h-5 *:w-full">
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
      </div>
    </div>
  );
}

export function RulesSectionDesktop({ rules }: IRulesSectionProps) {
  const { $t } = useTranslate();

  return (
    <div className="pointer-events-none absolute w-full max-w-screen-3xl flex-c-sb">
      <RuleContent title={$t({ defaultMessage: "how it works" })} description={rules?.general} />
      <RuleContent title={$t({ defaultMessage: "daily wheel rules" })} description={rules?.daily} />
    </div>
  );
}
