import { apiClient } from "@lobby/api-client";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Lobby } from "../lobby";

export const FortuneWheel = {
	useWheelSettings() {
		return useQuery({
			refetchInterval: 1000 * 15,
			queryKey: ["FortuneWheel.getSettings"],
			queryFn: () =>
				apiClient.send({
					endpoint: "FortuneWheel.getSettings",
				}),
		});
	},
	useWheelWinner() {
		return useQuery({
			queryKey: ["FortuneWheel.getWin"],
			queryFn: () =>
				apiClient.send({
					endpoint: "FortuneWheel.getWin",
				}),
			select: (data) => data.result,
		});
	},
	useWheelSpin() {
		return useMutation({
			mutationKey: ["FortuneWheel.getWin"],
			mutationFn: () =>
				apiClient.send({
					endpoint: "FortuneWheel.getWin",
				}),
		});
	},
	useIsWheelAvailable() {
		const { data } = Lobby.useLobby();
		return Boolean(data?.fortuneWheel?.isActive);
	},
};
