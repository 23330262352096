import { LobbySettingsContext } from "@lobby/core/entities/lobby";
import { getProviderImageSrc } from "@lobby/core/shared";
import { useNavigate } from "@tanstack/react-router";
import { clsx } from "clsx";
import { type PropsWithChildren, useContext } from "react";

interface IProviderButtonProps {
  className?: string;
  name: string;
  id: number;
  isActive?: boolean;
  stringId: string;
  onClick?: () => void;
}

export function ProviderButton({
  className,
  name,
  id,
  isActive,
  stringId,
  onClick,
}: PropsWithChildren<IProviderButtonProps>) {
  const navigate = useNavigate();
  const lobbyContext = useContext(LobbySettingsContext);

  return (
    <button
      className={clsx(
        className,
        isActive ? "border-accent" : "border-transparent",
        "relative flex-center rounded-5 border-2 bg-dark-charcoal hover:bg-black-olive",
      )}
      type="button"
      title={name}
      onClick={() => {
        navigate({
          replace: true,
          // @ts-ignore
          search: (prev) => ({
            ...prev,
            game: undefined,
            cid: undefined,
            pid: prev.pid === id ? undefined : id,
          }),
        });

        if (onClick) {
          onClick();
        }
      }}
    >
      <span className="invisible absolute max-w-full truncate text-sm has-[+.hidden]:visible lg:text-lg">
        {name}
      </span>
      <img
        src={getProviderImageSrc(lobbyContext.theme, stringId)}
        alt={stringId}
        onError={(e) => (e.target as HTMLImageElement).classList.add("hidden")}
        className="size-full"
      />
    </button>
  );
}
