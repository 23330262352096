import { redirectIfAuthenticatedUserRequired } from "@app/lib";
import { loadLobbyConfig } from "@app/lib/loaders";
import { CongratsModal } from "@features/congrats-modal";
import { createFileRoute } from "@tanstack/react-router";
import { Outlet } from "@tanstack/react-router";
import { ProfileModal } from "@widget/profile-modal";

export const Route = createFileRoute("/_auth")({
  beforeLoad: () => redirectIfAuthenticatedUserRequired(),
  loader: () => Promise.all([loadLobbyConfig()]),
  component: () => (
    <>
      <ProfileModal />
      <CongratsModal />
      {/* <ContactsModal /> */}

      <Outlet />
    </>
  ),
});
