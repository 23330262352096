import { FavouriteBtn } from "@entities/game";
import { MobileMenuButton } from "@features/mobile-menu-button";
import { Game } from "@lobby/core/entities/game";
import { SVGIcon } from "@shared/ui";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";

interface IGamePageControlsMobileProps {
  gameStringId: string;
  onGameClose?: () => void;
}

export function GamePageControlsMobile({
  gameStringId,
  onGameClose,
}: IGamePageControlsMobileProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();

  const favouriteMutation = Game.useFavouriteSetter();
  const game = Game.getGameByStringId(gameStringId);

  const isFavourite = Boolean(game?.isFavourite);
  const gameId = Number(game?.id);

  return (
    <div
      className={`absolute right-0 bottom-0 translate-y-1 bg-raisin-black/50 transition-transform ${isExpanded ? "translate-x-0" : "translate-x-1"}`}
    >
      <div className="flex items-center">
        <div
          className="-translate-x-1 fixed top-0 left-0 h-full w-8 flex-center border-dark-charcoal border-r-1 bg-raisin-black/50 backdrop-blur-sm"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <SVGIcon
            className={`size-4 text-accent ${isExpanded ? "rotate-0" : "rotate-180"}`}
            name="arrowRight"
          />
        </div>
        <ul className="flex h-full items-center px-1.5 *:flex-center *:p-3">
          <li>
            <button
              type="button"
              onClick={() => {
                onGameClose?.();
                navigate({ to: "/casino" });
              }}
            >
              <SVGIcon className="size-5 text-accent" name="close" />
            </button>
          </li>

          <li>
            <FavouriteBtn
              isFavourite={isFavourite}
              onClick={() => favouriteMutation.mutate({ gameId, isFavourite: !isFavourite })}
            />
          </li>

          <li>
            <MobileMenuButton />
          </li>
        </ul>
      </div>
    </div>
  );
}
