import { APIError, emitter, useErrorTranslate } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Button, Modal, SVGIcon } from "@shared/ui";
import { useEffect, useState } from "react";

export function ErrorModal() {
  const { $t } = useTranslate();
  const [isOpened, setIsOpened] = useState(false);
  const [error, setError] = useState<Error>();
  const { formatMessage } = useErrorTranslate();

  const timeFormatOptions = Intl.DateTimeFormat().resolvedOptions();

  const localTime = new Date()
    .toLocaleString(timeFormatOptions.locale, {
      timeZone: timeFormatOptions.timeZone,
      timeZoneName: "longOffset",
      hour12: false,
    })
    .replace("GMT", "UTC");

  const timeGMT = new Date()
    .toLocaleString(timeFormatOptions.locale, {
      timeZone: "UTC",
      timeZoneName: "longOffset",
      hour12: false,
    })
    .replace("GMT", "UTC-0");

  useEffect(() => {
    const unbind = emitter.on("ERROR_MODAL_OPEN", (error) => {
      setIsOpened(true);
      setError(error);
    });

    return unbind;
  }, []);

  return (
    <Modal open={isOpened}>
      <Modal.Overlay />

      <Modal.Panel
        className="w-max max-w-[90%] rounded-5 border-1 border-carnation bg-carnation/30 p-2.5 lg:min-w-96 lg:max-w-[42.5rem]"
        centered
      >
        <div className="rounded-2 bg-cod-gray">
          <div className="px-5 py-5 lg:px-10 lg:py-5">
            <div className="grid grid-cols-[2.25rem,1fr] gap-5">
              <div className="h-24 *:size-full">
                <SVGIcon name="redCard" />
              </div>

              <div>
                <div className="font-bold text-carnation text-sm/none lg:text-18/none">
                  {$t({ defaultMessage: "error" }).toUpperCase()}
                </div>

                {error && (
                  <p className="my-5 whitespace-break-spaces font-medium text-sm lg:text-18">
                    {formatMessage(error)}
                  </p>
                )}

                <div className="mt-2 text-12 lg:text-base">
                  {error instanceof APIError && error?.details && (
                    <div>
                      {Object.entries(error.details).map(([key, value]) => {
                        if (!value) return null;

                        return (
                          <div key={key}>
                            <span className="capitalize">{key}: </span>
                            <span> {String(value)}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  <div>Local time: {localTime}</div>
                  <hr className="my-2" />
                  <div>UTC time: {timeGMT}</div>
                </div>
              </div>
            </div>
            <Button type="danger" onClick={() => setIsOpened(false)} className="mx-auto mt-6">
              {$t({ defaultMessage: "ok" }).toUpperCase()}
            </Button>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
}
