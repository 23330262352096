import { GameCard } from "@entities/game";
import { range } from "@lobby/core/shared";

export function GameListSkeleton() {
  return (
    <div className="rounded-rounded bg-athens-gray dark:bg-outer-space">
      <div className="flex items-center justify-between">
        <div className="rounded-rounded animate-pulse bg-alto dark:bg-bright-gray w-60 h-7" />
      </div>

      <div className="mt-5">
        <div className="grid gap-x-5 gap-y-10 grid-flow-col auto-cols-[9.4375rem] overflow-x-auto snap-x [scrollbar-color:#dedede_transparent] dark:[scrollbar-color:auto] h-[11.625rem]">
          {range(18)((idx) => (
            <GameCard.Skeleton key={idx} />
          ))}
        </div>
      </div>
    </div>
  );
}
