import loaderImg from "@app/assets/spinner.png";
import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";

export function Spin({ className }: { className?: string }) {
  const { $t } = useTranslate();

  return (
    <div className={clsx("w-fit flex-center flex-col", className)}>
      <div className="size-24">
        <img className="animate-spin" src={loaderImg} alt="loader" width="100%" height="100%" />
      </div>
      <div className="mt-1 font-medium">{$t({ defaultMessage: "Loading..." })}</div>
    </div>
  );
}
