import { SupportChatBtn } from "@features/support-chat-btn";
import { queryClient } from "@lobby/core/app";
import { Chat, Lobby } from "@lobby/core/entities";
import { clsx } from "clsx";
import { useEffect, useState } from "react";
import { SupportChartWindow } from "./support-chat-window";

export function SupportChatGuard({ className }: { className?: string }) {
  const { data: lobbyData } = Lobby.useLobby();

  const isConversationActive = lobbyData?.isIMActive ?? false;

  return (
    isConversationActive && (
      <div className={clsx("relative max-w-full", className)}>
        <SupportChat />
      </div>
    )
  );
}

function SupportChat() {
  const [isMinimized, setIsMinimized] = useState(true);

  const conversations = Chat.useConversationList();
  const conversation = conversations.data?.result?.[0] ?? null;

  const isRead = conversation?.isRead ?? true;

  function onChatWindowClose() {
    queryClient.invalidateQueries({ queryKey: ["Conversation.getList"] });
    setIsMinimized(true);
  }

  function onChatWindowOpen() {
    const isMessagesFetching =
      queryClient.isFetching({ queryKey: ["Conversation.getMessages"] }) > 0;
    if (!isMessagesFetching) {
      queryClient.invalidateQueries({
        queryKey: ["Conversation.getMessages"],
        refetchType: "all",
      });
    }
    setIsMinimized(false);
  }

  useEffect(() => {
    if (isMinimized && !isRead) {
      queryClient.invalidateQueries({ queryKey: ["Conversation.getMessages"] });
    }
  }, [isMinimized, isRead]);

  return isMinimized ? (
    <div className="absolute right-0 bottom-0">
      <SupportChatBtn hasUnreadMessages={!isRead} onClick={onChatWindowOpen} />
    </div>
  ) : (
    <SupportChartWindow onMinimized={onChatWindowClose} />
  );
}
