import { useModal } from "@lobby/core/shared";
import { PasswordResetModal } from "@widget/password-reset-modal";
import { useEffect } from "react";

export function PasswordResetPage() {
  const { open } = useModal("password-reset");

  useEffect(() => open(), [open]);

  return <PasswordResetModal isStatic />;
}
