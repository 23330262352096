import { apiClient } from "@lobby/api-client";
import type { SendOptions } from "@lobby/api-client";
import {
	onlineManager,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { APIError, CLIENT_ERRORS, emitter, useAuth } from "../../shared";

export const Player = {
	useLogIn() {
		const queryClient = useQueryClient();
		const navigate = useNavigate();

		return useMutation({
			mutationKey: ["Player.logIn"],
			mutationFn: (params: SendOptions<"Player.logIn">["params"]) =>
				apiClient.send<"Player.logIn">({
					endpoint: "Player.logIn",
					params,
				}),
			onSuccess: async (data) => {
				if (data.result) {
					await queryClient.invalidateQueries({ refetchType: "all" });
					emitter.emit("PLAYER_LOGIN");
					await navigate({ to: "/" });
				}
			},
		});
	},

	useLogOut() {
		const queryClient = useQueryClient();
		const route = useRouter();

		return useMutation({
			mutationKey: ["Player.logOut"],
			mutationFn: () =>
				apiClient.send<"Player.logOut">({
					endpoint: "Player.logOut",
				}),
			onSuccess: () => {
				queryClient.setQueryData(["Player.getCurrent"], {});
				route.invalidate();
			},
		});
	},

	usePlayer() {
		return useQuery({
			refetchInterval: 1000 * 15,
			queryKey: ["Player.getCurrent"],
			queryFn: () =>
				apiClient.send({
					endpoint: "Player.getCurrent",
					params: { isActive: "true" },
				}),
			select: (data) => data.result,
		});
	},

	useUpdatePlayer() {
		const queryClient = useQueryClient();

		return useMutation({
			mutationFn: (params: SendOptions<"Player.update">["params"]) =>
				apiClient.send<"Player.update">({
					endpoint: "Player.update",
					params,
				}),
			onSuccess: (data) => {
				if (data.error) {
					console.error(data.error);
				}

				if (data.result === "success") {
					queryClient.invalidateQueries({ queryKey: ["Player.getCurrent"] });
				}
			},
		});
	},

	useBonusHistory() {
		const { isAuth } = useAuth();

		return useQuery({
			staleTime: 1000 * 15,
			enabled: isAuth,
			queryKey: ["Bonus.getHistory"],
			queryFn: async () => {
				const result = await apiClient.send({
					endpoint: "Bonus.getHistory",
				});

				if (result.error) {
					console.error(result.error);
				}

				return result;
			},
			select: (data) => data?.result?.data,
		});
	},

	useBonusDescription() {
		const { isAuth } = useAuth();

		return useQuery({
			staleTime: 1000 * 15,
			enabled: isAuth,
			queryKey: ["Bonus.getDescription"],
			queryFn: async () => {
				const result = await apiClient.send({
					endpoint: "Bonus.getDescription",
				});

				if (result.error) {
					console.error(result.error);
				}

				return result;
			},
		});
	},

	useActivateBonus() {
		const queryClient = useQueryClient();
		const { data: player } = this.usePlayer();

		return useMutation({
			networkMode: "always",
			mutationKey: ["Bonus.activate"],
			mutationFn: () => {
				if (!onlineManager.isOnline()) {
					emitter.emit("ERROR_MODAL_OPEN", new Error(CLIENT_ERRORS[0]));
				}

				return apiClient.send<"Bonus.activate">({
					endpoint: "Bonus.activate",
				});
			},
			onError: (error) =>
				emitter.emit("ERROR_MODAL_OPEN", new APIError(error.message)),
			onSuccess: (data) => {
				if (data.error) {
					const apiError = new APIError(data.error.message, {
						code: data.error.code,
						PID: player?.id ? `${player.id} / ${player.hallId}` : null,
					});

					emitter.emit("ERROR_MODAL_OPEN", apiError);
				}

				queryClient.invalidateQueries({ queryKey: ["Player.getCurrent"] });
				queryClient.invalidateQueries({ queryKey: ["Player.getBalancesEx"] });
				queryClient.invalidateQueries({ queryKey: ["Bonus.getHistory"] });
			},
		});
	},

	useBalances() {
		const { isAuth } = useAuth();

		return useQuery({
			refetchInterval: 1000 * 15,
			staleTime: 1000 * 15,
			enabled: isAuth,
			queryKey: ["Player.getBalancesEx"],
			queryFn: async () => {
				const result = await apiClient.send({
					endpoint: "Player.getBalancesEx",
				});

				if (result.error) {
					console.error(result.error);
				}

				return result;
			},
			select: (data) => data.result,
		});
	},

	useChangePassword() {
		return useMutation({
			mutationKey: ["Player.changePassword"],
			mutationFn: (params: SendOptions<"Player.changePassword">["params"]) =>
				apiClient.send<"Player.changePassword">({
					endpoint: "Player.changePassword",
					params,
				}),
			onSuccess: (data) => {
				if (data.error) {
					console.error(data.error);
				}
			},
		});
	},

	useRegister() {
		return useMutation({
			mutationKey: ["Player.register"],
			mutationFn: (params: SendOptions<"Player.register">["params"]) =>
				apiClient.send<"Player.register">({
					endpoint: "Player.register",
					params,
				}),
			onSuccess: (data) => {
				if (data.error) {
					console.error(data.error);
				}
			},
		});
	},

	useResetPassword() {
		return useMutation({
			mutationKey: ["Player.resetPassword"],
			mutationFn: (params: SendOptions<"Player.resetPassword">["params"]) =>
				apiClient.send<"Player.resetPassword">({
					endpoint: "Player.resetPassword",
					params,
				}),
			onSuccess: (data) => {
				if (data.error) {
					console.error(data.error);
				}
			},
		});
	},
};
