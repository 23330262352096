import { useTranslate } from "@lobby/ocb-intl";
import { SVGIcon } from "@shared/ui";
import { clsx } from "clsx";
import type { PropsWithChildren } from "react";

interface INavigationBackMobileProps {
  className?: string;
  onClick: VoidFunction;
}

export function NavigationBackMobile({
  className,
  children,
  onClick,
}: PropsWithChildren<INavigationBackMobileProps>) {
  const { $t } = useTranslate();
  return (
    <div className={clsx(className, "rounded-4 bg-raisin-black p-3 text-14")}>
      <div className="font-medium uppercase">{children}</div>
      <button
        className="mt-2 flex h-9 w-full items-center justify-between rounded-inherit bg-dark-charcoal p-3 font-medium text-accent text-sm uppercase"
        type="button"
        onClick={onClick}
      >
        <span>{$t({ defaultMessage: "back to menu" })}</span>
        <SVGIcon className="w-2" name="arrowLeft" />
      </button>
    </div>
  );
}
