import { SVGIcon } from "@shared/ui";
import { clsx } from "clsx";
import type { HTMLAttributes } from "react";

export function NextPageLoader({ className, ...restProps }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx("my-2 flex h-10 items-center justify-center", className)} {...restProps}>
      <SVGIcon className="animate-spin text-20 text-accent" name="reload" />
    </div>
  );
}
