import { WHEEL_REVOLUTIONS_TO_WIN, sectorColors, sectorFillings } from "./const";

import type { TSector } from "./types";

const degreesToRadiansFactor = Math.PI / 180;

export function calcPointX(a: number, r: number, angle: number) {
  return a + r * Math.cos(angle * degreesToRadiansFactor);
}

export function calcPointY(b: number, r: number, angle: number) {
  return b + r * Math.sin(angle * degreesToRadiansFactor);
}

export function calcPoint(ab: number, r: number, angle: number) {
  return `${calcPointX(ab, r, angle)} ${calcPointY(ab, r, angle)}`;
}

export function calcCircumference(r: number) {
  return 2 * Math.PI * r;
}

export function calcSectorAngle(sectorCount: number) {
  return 360 / sectorCount;
}

export function calcTotalRotation(sectorCount: number, stopSectorIndex: number) {
  const sectorAngle = calcSectorAngle(sectorCount);
  return (sectorCount - stopSectorIndex) * sectorAngle + WHEEL_REVOLUTIONS_TO_WIN * 360;
}

export function calcAcceleration(velocity: number, distance: number) {
  return velocity ** 2 / (2 * distance);
}

export function calcDistance(initialVelocity: number, time: number, acceleration: number) {
  return initialVelocity * time - 0.5 * acceleration * time ** 2;
}

export function buildSectorPath(R: number, r: number, sectorAngle: number, gap = 0) {
  const sectorHalfAngle = sectorAngle / 2;
  return `M${R} ${R - gap * sectorHalfAngle} L${calcPoint(R + gap, r + gap, -sectorHalfAngle - 90)} A${r} ${r} 0 0 1 ${calcPoint(R - gap, r - gap, sectorHalfAngle - 90)} Z`;
}

export function getSectorFilling(index: number) {
  return sectorFillings[index % sectorFillings.length];
}

export function getSectorColors(index: number): [string, string] {
  return sectorColors[index % sectorColors.length];
}

export function getWinSectorIndex(sectors: TSector[], id: number | undefined) {
  if (!id) return -1;
  return sectors.findIndex((sector) => id === sector.id);
}
