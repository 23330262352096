import { Icon, type OpenWalletIntegrationModalEventPayload, emitter } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Button, Modal } from "@shared/ui";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

export function WalletIntegrationWarningModal() {
  const { $t } = useTranslate();
  const [isOpened, setIsOpened] = useState(false);
  const [params, setParams] = useState<OpenWalletIntegrationModalEventPayload>();

  function handleOk() {
    setIsOpened(false);
    params?.onOk?.();
  }

  useEffect(() => {
    return emitter.on("WALLET_INTEGRATION_WARNING_MODAL_OPEN", (payload) => {
      setParams(payload);
      setIsOpened(true);
    });
  }, []);

  return (
    <Modal open={isOpened}>
      <Modal.Overlay />

      <Modal.Panel
        className="w-fit max-w-[calc(100%-1.25rem)] rounded-5 border-1 border-gold bg-gold/30 p-2.5 lg:min-w-96 lg:max-w-[43.75rem]"
        centered
      >
        <div className="rounded-2 bg-mercury dark:bg-modal_bg">
          <div className="p-5 lg:px-10 lg:py-5">
            <div className="flex items-center gap-5 text-gold">
              <Icon name="warningSign" className="text-[2.5rem]" />
              <div className="font-bold text-14/none lg:text-18/none">
                {$t({ defaultMessage: "please note" }).toUpperCase()}
              </div>
            </div>

            <p className="mt-3 font-medium text-14 lg:mt-4 lg:text-18">
              <FormattedMessage
                defaultMessage={
                  "When you switch to the game, your entire balance will be transferred to the game.{br}This is a feature of this game provider."
                }
                values={{ br: <br /> }}
              />
            </p>
            <p className="mt-3 text-12 lg:mt-4 lg:text-14">
              {$t({
                defaultMessage:
                  "There may be a situation where the balance in the system and in the game is different.",
              })}
            </p>
            {params?.isReturningBalanceAvailable && (
              <p className="text-12 lg:text-14">
                {$t({
                  defaultMessage:
                    "In order to return the balance from the game to system, you need to click EXIT (cross) on the screen in the game.",
                })}
              </p>
            )}

            <Button type="warning" onClick={handleOk} className="mx-auto mt-5 lg:mt-10">
              {$t({ defaultMessage: "ok" }).toUpperCase()}
            </Button>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
}
