import { Field, Input, Label } from "@headlessui/react";
import { Email } from "@lobby/core/entities";
import { APIError, useErrorTranslate, useMessage } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Button, SVGIcon } from "@shared/ui";
import { useNavigate, useParams } from "@tanstack/react-router";
import { useState } from "react";
import type { FormEvent } from "react";

export function PasswordResetForm() {
  const [password, setPassword] = useState("");
  const [passwordVerify, setPasswordVerify] = useState("");
  const [validationError, setValidationError] = useState("");

  const navigate = useNavigate();
  const emailToken = useParams({
    strict: false,
    select: (params) => (params as any)?.token as string,
  });

  const { $t } = useTranslate();
  const { formatMessage: translateError } = useErrorTranslate();
  const { mutate: setNewPasswordMutate } = Email.useSetNewPassword();
  const message = useMessage();

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!validateEmailToken(emailToken)) {
      return;
    }

    setValidationError("");

    const form = new FormData(e.currentTarget);

    if (validateForm(form)) {
      submitNewPassword(form);
    }
  }

  function submitNewPassword(form: FormData) {
    setNewPasswordMutate(
      {
        newPassword: form.get("password") as string,
        emailToken,
      },
      {
        onSuccess: (data) => {
          const error = data.error;
          if (error) {
            const message = translateError(new APIError(error.message, { code: error.code }));
            setValidationError(message);
          } else {
            setPassword("");
            setPasswordVerify("");
            showSuccessMessage();
          }
        },
      },
    );
  }

  function validateForm(form: FormData) {
    return form.has("password") && form.has("passwordVerify") && validatePasswords(form);
  }

  function validateEmailToken(token: string) {
    if (!token) {
      setValidationError($t({ defaultMessage: "Invalid email token" }));
      return false;
    }

    return true;
  }

  function validatePasswords(form: FormData) {
    const password = form.get("password") as string;
    const passwordVerify = form.get("passwordVerify") as string;

    if (password !== passwordVerify) {
      setValidationError($t({ defaultMessage: "Passwords do not match" }));
      return false;
    }

    return true;
  }

  function showSuccessMessage() {
    message.success(
      $t({
        defaultMessage:
          "Password has been successfully changed\nYou will be redirected to sign in page in 3 seconds",
      }),
      {
        duration: 3000,
        onClose: () => navigate({ to: "/sign-in", replace: true }),
      },
    );
  }

  return (
    <div className="relative">
      <form onSubmit={handleSubmit} className="space-y-3">
        <Field className="flex flex-col">
          <Label>{$t({ defaultMessage: "Password" })}:</Label>
          <InputPassword name="password" value={password} onChange={setPassword} />
        </Field>

        <Field className="flex flex-col">
          <Label>{$t({ defaultMessage: "Confirm password" })}:</Label>
          <InputPassword
            name="passwordVerify"
            value={passwordVerify}
            onChange={setPasswordVerify}
          />
        </Field>

        {validationError && (
          <div className="text-center text-12 text-carnation lg:text-14">{validationError}</div>
        )}

        <Button className="mx-auto" htmlType="submit">
          {$t({ defaultMessage: "Submit" })}
        </Button>
      </form>
    </div>
  );
}

interface IInputPasswordProps {
  name: string;
  value: string;
  onChange: (value: string) => void;
}

function InputPassword({ name, value, onChange }: IInputPasswordProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <div className="relative">
      <Input
        className="w-full pr-13"
        name={name}
        type={isPasswordVisible ? "text" : "password"}
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
        autoComplete="new-password"
        minLength={6}
        maxLength={255}
        required
      />
      <div
        className="-translate-y-1/2 absolute top-1/2 right-0 p-3 text-white hover:cursor-pointer hover:text-white/80"
        onClick={() => setIsPasswordVisible((state) => !state)}
      >
        {isPasswordVisible ? <SVGIcon name="openedEye" /> : <SVGIcon name="closedEye" />}
      </div>
    </div>
  );
}
