import { LanguageSelector } from "@features/language-selector";
import { LoginForm } from "@features/log-in-form";
import { Lobby } from "@lobby/core/entities";
import { emitter, useAuth, useModal } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Button } from "@shared/ui";
import { Logo } from "@shared/ui/logo";
import { Modal } from "@shared/ui/modal";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

export function LogInModal({ isStatic = false }: { isStatic?: boolean }) {
  const { isOpen, close } = useModal("log-in");

  useEffect(() => {
    const unbind = emitter.on("PLAYER_LOGIN", close);
    return unbind;
  }, [close]);

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={isStatic ? undefined : close} />
      <LogInModalComponent />
    </Modal>
  );
}

function LogInModalComponent() {
  const { open: openRegisterModal } = useModal("register");
  const navigate = useNavigate();
  const { $t } = useTranslate();

  const { isAuth } = useAuth();
  const { data: lobbyData } = Lobby.useLobby();

  const isRegistrationEnabled = lobbyData?.registration.enabled ?? false;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isAuth) {
      navigate({
        to: "/",
        replace: true,
      });
    }
  }, [isAuth]);

  return (
    <Modal.Panel className="mobile-only:min-h-full w-full lg:top-17 lg:w-[25rem]">
      <Logo className="mx-auto h-10" />
      <div className="mt-5 flex-c-sb gap-5">
        <div className="font-bold text-14 uppercase lg:text-16">
          {$t({ defaultMessage: "Log In" })}
        </div>
        {isRegistrationEnabled && (
          <Button onClick={openRegisterModal}>{$t({ defaultMessage: "Sign up" })}</Button>
        )}
      </div>

      <LoginForm className="mt-7 mobile-only:mb-10" />

      <div className="mt-auto flex items-center justify-between">
        <LanguageSelector orientation="top" />
      </div>
    </Modal.Panel>
  );
}
