import { createContext, useContext } from "react";
import type { ISlot, IWin } from "./types";

export interface IJackpotsContext {
  wins: IWin[] | null;
  slots: ISlotView[] | null;
  fetchWinAccept: (windId: number) => void;
  error: string | null;
}

export type ISlotView = Pick<ISlot, "name" | "value" | "slot">;

const initialAppContextValue: IJackpotsContext = {
  slots: null,
  wins: null,
  fetchWinAccept: () => null,
  error: null,
};

export const JackpotContext = createContext<IJackpotsContext>(initialAppContextValue);

export const useJackpotsContext = () => {
  const context = useContext(JackpotContext);
  if (!context) {
    throw new Error("maybe the application is not wrapped in JackpotsProvider Component");
  }

  return context;
};
