import clsx from "clsx";
import { type ForwardedRef, forwardRef } from "react";

interface IInputProps {
  className?: string;
  label?: string;
  requiredMessage?: string;
}

export const Input = forwardRef(function Input(
  { className, label, requiredMessage, ...inputProps }: IInputProps & React.ComponentProps<"input">,
  ref?: ForwardedRef<HTMLInputElement>,
) {
  return (
    <label className={clsx(className, "flex flex-col gap-1 text-14 lg:text-base")}>
      {label && <span className="font-bold">{label}:</span>}
      <div className="relative grow">
        <input className="peer w-full" ref={ref} {...inputProps} />
        <div className="invisible absolute left-2.5 font-light text-10 text-carnation peer-[:user-invalid]:visible lg:text-12">
          {requiredMessage}
        </div>
      </div>
    </label>
  );
});
