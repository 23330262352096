import { useTranslate } from "@lobby/ocb-intl";
import { Button, Logo, SVGIcon } from "@shared/ui";
import { Link } from "@tanstack/react-router";
import { clsx } from "clsx";

export function Footer({ className }: { className?: string }) {
  const { $t } = useTranslate();

  return (
    <footer className={clsx("relative", className)}>
      <div className="mx-auto p-5 lg:max-w-screen-3xl">
        <div className="flex flex-col gap-2 lg:contents">
          <div className="flex-c-sb gap-3 lg:gap-5">
            <Link className="shrink-0" to="/">
              <Logo className="h-10" />
            </Link>

            <div
              className="flex-center cursor-pointer gap-2.5 whitespace-nowrap text-base text-dove-gray"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <span className="hidden">
                {$t({ defaultMessage: "18+" })}
                {$t({
                  defaultMessage:
                    "Gambling can be addictive. Play responsibly. {themeName} only accept customers over 18 years of age.",
                })}
                {$t({ defaultMessage: "game" })}
                {$t({ defaultMessage: "responsibly" })}
              </span>

              <span>{$t({ defaultMessage: "Back to top" })}</span>
              <Button fit suppressed>
                <SVGIcon className="-rotate-90 text-8 lg:text-10" name="arrowRight" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
