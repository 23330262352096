import { emitter } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Skeleton } from "@shared/ui/skeleton";
import { useNavigate } from "@tanstack/react-router";
import clsx from "clsx";
import type { SyntheticEvent } from "react";

export function GameCardSkeleton() {
  return (
    <div className="h-full snap-start rounded-rounded bg-white dark:bg-ebony-clay">
      <Skeleton className="h-[8.125rem]" />
      <div className="p-2.5">
        <Skeleton className="h-4" />
        <Skeleton className="mt-1 h-4" />
      </div>
    </div>
  );
}

interface IFavouriteBtnProps {
  className?: string;
  isFavourite: boolean;
  onClick: VoidFunction;
}

export function FavouriteBtn({ className, isFavourite, onClick }: IFavouriteBtnProps) {
  return (
    <button className={clsx("group/fav transform-gpu", className)} type="button" onClick={onClick}>
      <svg className="text-20 lg:text-24" viewBox="0 0 24 22" width="1em" height="0.9063em">
        <path
          className={clsx(
            "group-hover/fav:stroke-mangoose",
            isFavourite ? "fill-carnation stroke-white" : "fill-white",
          )}
          d="M2.42 2.58a6.25 6.25 0 0 0 0 8.84l.883.883 8.84 8.84v-.001l8.838-8.839.884-.884a6.25 6.25 0 1 0-8.839-8.838l-.884.883-.884-.884a6.25 6.25 0 0 0-8.838 0Z"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}

export function GameCardLinkLayer({
  gameId,
  showWalletIntegrationWarning,
}: { gameId: string; showWalletIntegrationWarning: boolean }) {
  const { $t } = useTranslate();
  const navigate = useNavigate();

  function navigateToGame() {
    navigate({ to: "/game/$gameId", params: { gameId } });
  }

  function showWarningModal() {
    emitter.emit("WALLET_INTEGRATION_WARNING_MODAL_OPEN", {
      isReturningBalanceAvailable: true,
      onOk: navigateToGame,
    });
  }

  function handleClick() {
    if (showWalletIntegrationWarning) {
      showWarningModal();
    } else {
      navigateToGame();
    }
  }

  return (
    <button onClick={handleClick} type="button" className="game-card__link-layer">
      <div className="game-card__play-btn">{$t({ defaultMessage: "play" })}</div>
    </button>
  );
}

const onImageLoadError = (e: SyntheticEvent<HTMLImageElement>) => {
  (e.target as HTMLImageElement).style.opacity = "0";
  // @ts-ignore
  (e.target as HTMLImageElement).parentElement.style.backgroundColor = "#444";
};

export function GameCardImage({ src, title }: { src: string; title: string }) {
  return (
    <div className="game-card__img-container aspect-square">
      <picture>
        <source srcSet={src.replace(".jpg", ".webp")} type="image/webp" />
        <img
          className="object-contain"
          src={src}
          alt={title}
          loading="lazy"
          width="100%"
          height="100%"
          onError={onImageLoadError}
        />
      </picture>
    </div>
  );
}

export function GameCardDescription({
  provider,
  gameName,
}: { provider: string; gameName: string }) {
  return (
    <div className="game-card__description">
      <div className="text-11 text-dove-gray">{provider}</div>
      <div className="truncate text-14" title={gameName}>
        {gameName}
      </div>
    </div>
  );
}
