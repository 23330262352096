import { Button, SVGIcon } from "@shared/ui";
import { useNavigate } from "@tanstack/react-router";

export function ResetProviderFilterButton() {
  const navigate = useNavigate();

  return (
    <Button
      fit
      onClick={() => {
        navigate({
          replace: true,
          // @ts-ignore
          search: (prev) => ({ ...prev, pid: undefined }),
        });
      }}
    >
      <SVGIcon name="close" />
    </Button>
  );
}
