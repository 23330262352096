export type TJackpotsType = "mini" | "major" | "grand" | "ultimate";

export interface ISlot {
  isCommunity: boolean | null;
  jackpotId: string;
  value: number;
  slot: number;
  name: TJackpotsType;
}

export const JackpotMap: TJackpotsType[] = ["mini", "major", "grand", "ultimate"];

export interface IWin {
  value: number;
  id: number;
  state: "pending" | "paid" | "canceled";
  isViewed?: boolean;
  isCommunity?: boolean;
  jackpotId: string;
  slot: number;
}
