import { LogoutButton } from "@features/player/logout-button";
import { useMobile } from "@lobby/core/shared";
import { SVGIcon } from "@shared/ui";
import type { TIconName } from "@shared/ui";
import { useNavigate } from "@tanstack/react-router";
import type { TProfileSection } from "@widget/profile-modal/lib";
import { clsx } from "clsx";
import type { TProfileSectionItem } from "./profile-sections";

interface IProfileSectionControlsProps {
  currentSectionId: TProfileSection | undefined;
  items: TProfileSectionItem[];
}

export function ProfileSectionControls({ items, currentSectionId }: IProfileSectionControlsProps) {
  const navigate = useNavigate();
  const isMobile = useMobile();

  return (
    <div className="flex flex-col rounded-xs bg-raisin-black p-3 lg:rounded-10 lg:p-5">
      <ul className="tw-scrollbar flex mobile-only:max-h-max flex-col gap-2.5 py-1 lg:h-[16.75rem] lg:overflow-y-scroll">
        {items.map(({ title, icon, id }, idx) => (
          <li key={idx} className="mr-1 *:w-full">
            <SectionBtn
              label={title}
              iconName={icon as TIconName}
              active={currentSectionId === id}
              onClick={() =>
                navigate({
                  // @ts-ignore
                  search: (prev) => ({ ...prev, profileSection: id }),
                })
              }
            />
          </li>
        ))}
      </ul>
      {!isMobile && <LogoutButton className="mt-5" />}
    </div>
  );
}

interface SectionBtnProps {
  label: string;
  iconName: TIconName;
  active: boolean;
  onClick: VoidFunction;
}

function SectionBtn({ label, iconName, active, onClick }: SectionBtnProps) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={clsx(
        active && "bg-gradient-accent",
        "group flex items-center gap-2 whitespace-normal rounded-4 bg-dark-charcoal px-5 py-[0.9375rem] text-start font-medium hover:bg-gradient-accent",
      )}
    >
      <SVGIcon
        className={`text-18 group-hover:text-white ${active ? "text-white" : "text-dove-gray"}`}
        name={iconName}
      />
      <span>{label}</span>
    </button>
  );
}
