import { GameHistory as GameHistoryModel, usePlayerMoneyFormatter } from "@lobby/core/entities";
import { useInfiniteScroll, useMobile } from "@lobby/core/shared";
import { useIntlT, useTranslate } from "@lobby/ocb-intl";
import { SVGIcon } from "@shared/ui";
import { Spin } from "@shared/ui/spin";
import { getMobileSectionScrollContainer } from "@widget/profile-modal/lib";
import { clsx } from "clsx";
import { useMemo } from "react";
import { NextPageLoader } from "../../components";
import { NoDataPlaceholder } from "../../no-data-placeholder";

import "./styles.css";

export function GameHistory({ className }: { className?: string }) {
  const isMobile = useMobile();
  const { data, fetchNextPage, isFetchingNextPage, isLoading } = GameHistoryModel.useBetHistory();

  const scrollContainer = isMobile ? getMobileSectionScrollContainer() : null;

  const scrollRef = useInfiniteScroll(fetchNextPage, scrollContainer);
  const { $t } = useTranslate();
  const { intl } = useIntlT();
  const formatMoney = usePlayerMoneyFormatter();

  const gameHistory = useMemo(() => data?.pages.flatMap((page) => page.result?.data) ?? [], [data]);

  const isGameHistoryExist = gameHistory.length > 0;

  return (
    <div
      className={clsx(className, "mobile-only:relative flex h-full min-h-0 flex-col lg:max-h-full")}
    >
      <div className="tw-scrollbar gutter-stable lg:overflow-y-auto" ref={scrollRef}>
        {!isGameHistoryExist && !isFetchingNextPage && !isLoading && (
          <NoDataPlaceholder
            topText={$t({ defaultMessage: "The list of games will appear here soon" })}
            bottomText={$t({ defaultMessage: "Play your first game" })}
            icon={<SVGIcon name="gamepad" />}
          />
        )}

        {isLoading && (
          <div className="absolute inset-0 flex-center">
            <Spin />
          </div>
        )}

        {isGameHistoryExist && (
          <div className="lg:ml-2">
            <table className="game-history-table lg:-ml-2">
              <thead>
                <tr>
                  <th>{$t({ defaultMessage: "Date" })}</th>
                  <th>{$t({ defaultMessage: "Game" })}</th>
                  <th>{$t({ defaultMessage: "Bet" })}</th>
                  <th>{$t({ defaultMessage: "Win" })}</th>
                  <th>{$t({ defaultMessage: "Revenue" })}</th>
                </tr>
              </thead>
              <tbody>
                {gameHistory.map(({ createdAt, gameName, betSum, winSum, profitSum }, idx) => (
                  <tr key={idx}>
                    <td>
                      {new Date(createdAt * 1e3).toLocaleString(intl.locale, {
                        timeStyle: "short",
                        dateStyle: "short",
                      })}
                    </td>
                    <td>{gameName}</td>
                    <td>{formatMoney(betSum, { style: "decimal" })}</td>
                    <td>{formatMoney(winSum, { style: "decimal" })}</td>
                    <td>{formatMoney(profitSum, { style: "decimal" })}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {isFetchingNextPage && <NextPageLoader />}
          </div>
        )}
      </div>
    </div>
  );
}
