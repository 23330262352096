import * as Sentry from "@sentry/react";
import { clsx } from "clsx";
import { Icons } from "./icons";

interface IIconProps {
  className?: string;
  name: keyof typeof Icons;
}

export function Icon({ className, name }: IIconProps) {
  const Icon = Icons[name];

  return (
    <Sentry.ErrorBoundary fallback={<pre>%</pre>}>
      <div className={clsx(className, "flex-center empty:hidden")}>
        <Icon />
      </div>
    </Sentry.ErrorBoundary>
  );
}

export type TIconName = IIconProps["name"];
