import { useTranslate } from "@lobby/ocb-intl";
import { useEffect, useRef, useState } from "react";

import { Button } from "@shared/ui/button";

import type { TBanner } from "../lib/types";

interface IBannersListProps {
  banners: TBanner[];
}

export function BannersListMobile({ banners }: IBannersListProps) {
  const [activeBanners, setActiveBanners] = useState(banners);
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const { $t } = useTranslate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentSlideIdx((prev) => (prev + 1) % activeBanners.length);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [currentSlideIdx, activeBanners]);

  useEffect(() => {
    let touchstartX = 0;
    let touchendX = 0;

    function checkDirection() {
      if (touchendX + 20 < touchstartX) {
        setCurrentSlideIdx((prev) => (prev + 1) % activeBanners.length);
      }

      if (touchendX - 20 > touchstartX) {
        setCurrentSlideIdx((prev) => (prev - 1 + activeBanners.length) % activeBanners.length);
      }
    }

    const touchstartHandler = (e: TouchEvent) => {
      touchstartX = e.changedTouches[0].screenX;
    };

    const touchendHandler = (e: TouchEvent) => {
      touchendX = e.changedTouches[0].screenX;
      checkDirection();
    };

    containerRef.current?.addEventListener("touchstart", touchstartHandler);
    containerRef.current?.addEventListener("touchend", touchendHandler);

    return () => {
      containerRef.current?.removeEventListener("touchstart", touchstartHandler);
      containerRef.current?.removeEventListener("touchend", touchendHandler);
    };
  }, []);

  useEffect(() => {
    setActiveBanners(banners);
  }, [banners]);

  return (
    <div className="relative h-full">
      <div className="absolute flex size-full" ref={containerRef}>
        {activeBanners.map(({ image, title, subtitle, button }, idx) => (
          <div
            className={`absolute size-full min-w-full p-2.5 transition-opacity duration-500 ease-linear ${idx === currentSlideIdx ? "opacity-100" : "pointer-events-none opacity-0"}`}
            key={idx}
          >
            <img
              className="absolute top-0 left-0 h-full w-full object-cover"
              src={image}
              alt="promo-img"
            />
            <div className="absolute inset-0 rounded-inherit bg-gradient-to-r from-[#0D1523B8] to-[#0D152300]" />
            <div className="relative h-full">
              {(title || subtitle) && (
                <div className="absolute top-0 max-h-[85%] max-w-full overflow-hidden text-white [text-shadow:0.0625rem_0.125rem_0_#000]">
                  {title.split("\n").map((substr, idx) => (
                    <div key={idx} className="font-bold text-[8.375vmin] leading-tight">
                      {substr}
                    </div>
                  ))}
                  {subtitle.split("\n").map((substr, idx) => (
                    <div key={idx} className="text-[4.675vmin]">
                      {substr}
                    </div>
                  ))}
                </div>
              )}
              {button && (
                <div className="absolute bottom-0">
                  <Button type="primary" onClick={button.action}>
                    {button.textKey === "playNow"
                      ? $t({ defaultMessage: "play now" })
                      : $t({ defaultMessage: "open" })}
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <ul className="absolute right-5 bottom-5 flex gap-2 [line-height:0]">
        {activeBanners.length > 1 &&
          activeBanners.map((_, idx) => (
            <li key={idx}>
              <button
                type="button"
                onClick={() => setCurrentSlideIdx(idx)}
                className={`size-3 rounded-full border-2 border-white ${idx === currentSlideIdx ? "bg-white" : "bg-black-olive"}`}
              />
            </li>
          ))}
      </ul>
    </div>
  );
}
