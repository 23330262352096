import { clsx } from "clsx";
import { type HTMLAttributes, useEffect, useState } from "react";

import lights1 from "./assets/lights_01.png";
import lights2 from "./assets/lights_02.png";
import lights3 from "./assets/lights_03.png";
import lights4 from "./assets/lights_04.png";

const images = [lights1, lights2, lights3, lights4];

export function XMasDecorationLights({
  className,
  style,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  const [currentImageIdx, setCurrentImageIdx] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIdx((prev) => (prev + 1) % images.length);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={clsx("xmas-decoration-lights pointer-events-none", className)} {...props}>
      {images.map((img, i) => (
        <div
          key={i}
          style={{
            background: `url(${img}) top left / contain`,
            opacity: Number(i === currentImageIdx),
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        />
      ))}
    </div>
  );
}
