import { UnwagerGameList } from "./unwager-game-list";

export function Info() {
  return (
    <div className="mobile-only:relative flex h-full min-h-0 flex-col lg:max-h-full">
      <div className="tw-scrollbar gutter-stable lg:overflow-y-auto">
        <UnwagerGameList />
      </div>
    </div>
  );
}
