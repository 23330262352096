import { Lobby } from "@lobby/core/entities";
import { Skeleton } from "@shared/ui/skeleton";
import { useMobileBanners } from "../lib/use-banner";
import { BannersListMobile } from "./banners-list.mobile";

export function BannerPanelMobile() {
  const { isPending } = Lobby.useBanner();
  const banners = useMobileBanners();

  const isBannerEmpty = !banners || banners.length === 0;

  if (isBannerEmpty && !isPending) {
    return null;
  }

  return (
    <div className="group -mt-2.5 -mx-2.5 [aspect-ratio:2.206]">
      {isPending && <Skeleton className="h-full" />}
      {!isBannerEmpty && <BannersListMobile banners={banners} />}
    </div>
  );
}
