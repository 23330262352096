import { useMediaQuery } from "react-responsive";
import { MOBILE_BREAKPOINT } from "../../const";

export function useMobile() {
  return useMediaQuery({
    query: `(max-width: ${MOBILE_BREAKPOINT}px)`,
  });
}

export function useMobileLandscape() {
  return useMediaQuery({
    query: `(max-width: ${MOBILE_BREAKPOINT}px) and (orientation:landscape)`,
  });
}
