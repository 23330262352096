import { Chat, Player } from "@lobby/core/entities";
import { useMobile } from "@lobby/core/shared";
import { APIError, Alert, useErrorTranslate } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import type { ChangeEvent, KeyboardEvent } from "react";
import {
  ConversationWindow,
  ExpandWindowBtn,
  MinimizeWindowBtn,
  SendMessageBtn,
} from "./components";

interface ISupportChatWindowProps {
  onMinimized: VoidFunction;
}

const MAX_MESSAGE_LENGTH = 4096;

export function SupportChartWindow({ onMinimized }: ISupportChatWindowProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [isMessagesLoaded, setIsMessagesLoaded] = useState(false);
  const [error, setError] = useState<{
    code: number;
    message: string;
    playerId?: number;
    hallId?: number;
  } | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const isMobile = useMobile();
  const { $t } = useTranslate();
  const { formatMessage: translateError } = useErrorTranslate();

  const player = Player.usePlayer();
  const conversations = Chat.useConversationList();
  const { isPending, variables, mutate } = Chat.useSendMessage();

  const conversation = conversations.data?.result?.[0] ?? null;
  const conversationId = conversation?.id ?? null;
  const pendingMessage = isPending ? (variables?.text ?? null) : null;

  const isOverflowLimitMessageLength = useMemo(
    () => inputMessage.length >= MAX_MESSAGE_LENGTH,
    [inputMessage],
  );

  function clearInputMessage() {
    setInputMessage("");
  }

  function prepareMessage(message: string) {
    const computedMessage = message.trim();

    if (isOverflowLimitMessageLength) {
      throw new Error("Message is too long");
    }

    return computedMessage;
  }

  function sendMessage(message: string) {
    mutate(
      {
        ...(conversationId !== null && { conversationId }),
        text: message,
      },
      {
        onSettled: (data) => {
          if (data?.error) {
            setErrorWrapper({
              code: data.error.code,
              message: data.error.message,
            });
          }

          if (conversationId === null) {
            conversations.refetch();
          }
        },
      },
    );
  }

  function setErrorWrapper(error: { code: number; message: string }) {
    setError({
      code: error.code,
      message: translateError(new APIError(error.message, { code: error.code })),
      playerId: player.data?.id,
      hallId: player.data?.hallId,
    });
  }

  function processInputMessage() {
    const message = prepareMessage(inputMessage);
    if (message) {
      sendMessage(message);
      clearInputMessage();
    }
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setInputMessage(e.target.value);
  }

  function handleSendMessageBtnClick() {
    processInputMessage();
  }

  function handleInputKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      processInputMessage();
    }
  }

  useEffect(() => {
    if (conversations.data?.error) {
      setErrorWrapper(conversations.data.error);
    }
  }, [conversations.data?.error]);

  useEffect(() => {
    !isInputDisabled && inputRef.current?.focus();
  }, [isInputDisabled]);

  useEffect(() => {
    if (isMessagesLoaded && !isPending && !error) {
      setIsInputDisabled(false);
    } else {
      setIsInputDisabled(true);
    }
  }, [isMessagesLoaded, isPending, error]);

  return (
    <div
      className={clsx("support-chat-window", {
        "support-chat-window_expanded": isExpanded,
        "support-chat-window_minimized": isMinimized,
        "modal-dialog": isMobile && !isMinimized,
      })}
      onAnimationEnd={({ animationName }) => {
        animationName === "minimize-chat-window" && onMinimized();
      }}
    >
      {isMinimized ? (
        <div className="absolute inset-0 rounded-inherit bg-raisin-black" />
      ) : (
        <div className="flex h-full flex-col gap-1">
          <div className="-mt-1 -mx-1 flex items-end justify-between mobile-only:p-2 mobile-only:pb-0 text-lg">
            <MinimizeWindowBtn onClick={() => setIsMinimized(true)} />
            <ExpandWindowBtn
              className="mobile-only:hidden"
              onClick={() => setIsExpanded((v) => !v)}
            />
          </div>
          <div className="relative flex min-h-0 grow flex-col">
            <ConversationWindow
              conversationId={conversationId}
              pendingMessage={pendingMessage}
              isChatWindowExpanded={isExpanded} // Required to scroll to bottom when chat window is expanded
              onError={setErrorWrapper}
              onMessagesLoading={setIsMessagesLoaded}
            />
            {isOverflowLimitMessageLength && (
              <span className="relative text-carnation">
                {$t({ defaultMessage: "Message is too long" })}
              </span>
            )}
            <div className="relative h-15 min-h-15 rounded-b-rounded before:absolute before:top-[-0.0625rem] before:left-0 before:h-0.5 before:w-full before:bg-raisin-black">
              <input
                className="size-full rounded-inherit bg-cod-gray p-5 pr-13"
                ref={inputRef}
                type="text"
                autoFocus
                value={inputMessage}
                placeholder={$t({ defaultMessage: "Type your message here..." })}
                autoComplete="off"
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                disabled={isInputDisabled}
              />
              <SendMessageBtn
                disabled={isInputDisabled || isOverflowLimitMessageLength}
                onClick={handleSendMessageBtnClick}
              />
            </div>

            {error && (
              <div className="absolute inset-2 bottom-auto">
                <Alert
                  message={error.message}
                  type="error"
                  closable
                  afterClose={() => setError(null)}
                  description={
                    <>
                      <div>Code: {error.code}</div>
                      <div>
                        PID: {error.playerId} / {error.hallId}
                      </div>
                      <div>{new Date().toLocaleString()}</div>
                    </>
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
