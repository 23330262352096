import { type HTMLAttributes, type PropsWithChildren, useEffect, useRef } from "react";

export function TextFitter({
  children,
  className,
  ...restProps
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  const ref = useRef<HTMLDivElement>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    const fitText = () => {
      const parent = el.parentElement as HTMLElement;
      const parentFs = getComputedStyle(parent).getPropertyValue("font-size");
      el.style.fontSize = Number.parseInt(parentFs) + "px";

      while (parent.offsetWidth < el.scrollWidth) {
        const fs = getComputedStyle(el).getPropertyValue("font-size");
        el.style.fontSize = Number.parseInt(fs) - 1 + "px";
      }
    };

    fitText();
    window.addEventListener("resize", fitText);
    return () => window.removeEventListener("resize", fitText);
  }, [children]);

  return (
    <div ref={ref} className={className} {...restProps}>
      {children}
    </div>
  );
}
