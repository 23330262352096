import { type TJackpotsType, usePlayerMoneyFormatter } from "@lobby/core/entities";
import { clsx } from "clsx";

interface IJackpotsTickerProps {
  id: number;
  className?: string;
  value?: number;
  type: TJackpotsType;
}

export function JackpotsTicker({ id, className, type, value }: IJackpotsTickerProps) {
  const formatMoney = usePlayerMoneyFormatter();

  const hasValue = value !== null && value !== undefined;

  return (
    <div
      className={clsx(`jackpots__ticker jackpots-ticker jackpots-ticker_type_${type}`, className)}
      data-jp-ticker-id={id}
    >
      <div className="jackpots-ticker__inner">
        <div className="jackpots-ticker__text">
          <span className="text-[1.25em] lg:text-[1.75em]">{type}</span>
          {hasValue && (
            <span className="ml-[0.375em] text-[1.25em] lg:text-[1.75em]">
              {formatMoney(value)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
