import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters/dist";
import { Spin } from "@shared/ui/spin";
import { useNoWagerGames } from "./use-no-wager-games";

import "./style.css";

export function UnwagerGameList() {
  const { $t } = useTranslate();
  const { noJackpotGames, noWagerGames, isNoWagerLoading, isNoJackpotLoading } = useNoWagerGames();

  if (isNoJackpotLoading || isNoWagerLoading) {
    return (
      <div className="absolute inset-0 flex-center">
        <Spin />
      </div>
    );
  }

  const UnWagerGameList = noWagerGames && (
    <div className="flex flex-col gap-1">
      <p className="font-bold text-16 lg:text-18">
        {$t({
          defaultMessage: "A list of games in which the bonus cannot be wagered",
        })}
      </p>
      {Object.keys(noWagerGames || {}).map((providerName) => {
        return (
          <div key={providerName}>
            <span className="font-bold">
              {providerName}
              {skipIntl(":")}
            </span>
            <span className="comma">
              {noWagerGames?.[providerName]?.map((game) => (
                <span key={game.stringId} className="game-name ml-2">
                  {game.name}
                </span>
              ))}
            </span>
          </div>
        );
      })}
    </div>
  );

  const NoJackpotGameList = noJackpotGames && (
    <div className="flex flex-col gap-1">
      <p className="font-bold text-16 lg:text-18">
        {$t({
          defaultMessage:
            "List of games in which the jackpot does not accumulate and cannot be received",
        })}
      </p>
      {Object.keys(noJackpotGames || {}).map((providerName) => (
        <div key={providerName} className="">
          <span className="font-bold">
            {providerName} {skipIntl(":")}
          </span>
          <span className="comma">
            {noJackpotGames?.[providerName]?.map((game) => (
              <span key={game.stringId} className="game-name ml-2">
                {game.name}
              </span>
            ))}
          </span>
        </div>
      ))}
    </div>
  );

  return (
    <div className="mobile-only:w-[calc(100%-2rem)] space-y-5 text-14 lg:text-18">
      {UnWagerGameList}
      {NoJackpotGameList}
    </div>
  );
}
