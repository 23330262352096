import { ProviderButton } from "@features/provider-button";
import { ResetProviderFilterButton } from "@features/reset-provider-filter-button";
import { Game } from "@lobby/core/entities";
import { moveItemToHead, range } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { Button } from "@shared/ui";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useMemo } from "react";

export function ProviderList() {
  const { $t } = useTranslate();
  const pid = useSearch({ strict: false, select: ({ pid }) => pid as number });

  const { data, isPending } = Game.useProvidersList();

  const providerList = data?.result?.data;

  const sortedProviders = useMemo(
    () => moveItemToHead(pid, providerList || []),
    [providerList, pid],
  );

  const isProvidersButtonVisible = (data?.result?.total ?? 0) > 1;

  return (
    <section>
      <div className="flex items-center gap-2.5">
        <div className="whitespace-nowrap font-medium text-15 uppercase lg:mr-15 lg:text-22">
          {$t({ defaultMessage: "Providers" })}
        </div>
        <div className="flex h-10 flex-wrap gap-2.5 overflow-hidden lg:h-12.5">
          {isPending && range(8)((idx) => <ProviderButton.Skeleton key={idx} />)}
          {sortedProviders?.map(({ name, id, stringId }) => (
            <ProviderButton
              className="h-10 w-40 lg:h-12.5 lg:w-56"
              key={id}
              name={name}
              id={id}
              stringId={stringId}
              isActive={pid === id}
            />
          ))}
        </div>
        <div className="flex gap-2.5">
          {Boolean(pid) && <ResetProviderFilterButton />}
          {isProvidersButtonVisible && <ShowAllProvidersButton />}
        </div>
      </div>
    </section>
  );
}

function ShowAllProvidersButton() {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate({
          // @ts-ignore
          search: (prev) => ({ ...prev, modal: "providers" }),
          replace: true,
        });
      }}
    >
      {skipIntl(". . .")}
    </Button>
  );
}
