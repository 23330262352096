export const MOBILE_BREAKPOINT = 1023;

export const clientCategories: Record<string, { id: string; icon?: any }> = {
  searchResults: {
    id: "searchResults",
  },
  allGames: {
    id: "allGames",
    icon: "allGames",
  },
  favourites: {
    id: "favourites",
    icon: "heart",
  },
  recommended: {
    id: "recommended",
    icon: "recommended",
  },
};

export const clientCategoryIdMap: Record<string, string> = {
  favourites: "favourites",
  recommended: "recommended",
  allGames: "all games",
  searchResults: "search results",
};
