import { createFileRoute } from "@tanstack/react-router";

import { redirectIfAuthenticatedUserRequired } from "@app/lib";
import { redirectToIndexCategory } from "@app/lib/helpers";

export const Route = createFileRoute("/")({
  beforeLoad: async () => {
    await redirectIfAuthenticatedUserRequired();

    return redirectToIndexCategory();
  },
  onError: console.error,
});
